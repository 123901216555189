/*
 PC page
---------------------------------------- */

.page,
.single,
.archive {
  height: 100%;
  background: url(../img/page-side.png) repeat-y;
  .main-area {
    width: calc(100% - 221px);
    margin-left: 221px;
  }
  .white {
    display: none;
  }
  .header_tit {
    color: #888;
  }

  /* =============== hero =============== */
  .hero {
    width: 100%;
    height: 34vh;
    background: $cl-darkBlue url(../img/page-top.png) repeat-x center;
    background-size: auto 100%;
    color: #fff;
    position: relative;
    &_cont {
      width: 100%;
      position: absolute;
      z-index: 10;
      bottom: 10px;
      padding-left: 40px;
      .breadcrumb {
        width: 75%;
        text-align: right;
        margin: auto;
        ul {
          li {
            display: inline-block;
            font-size: $fs-12;
            color: #fff;
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
  &.contest {
    .hero {
      background: $cl-darkBlue url(../img/contest_hero.png) repeat-x center;
      background-size: auto 100%;
    }
  }
  &.contest_result {
    .hero {
      background: $cl-darkBlue url(../img/result_bg.png) repeat-x center;
      background-size: auto 100%;
    }
  }

  /* =============== main =============== */
  .main {
    @include clearfix;
    background-image: url(../img/bg-wh.png);
    min-height: 600px;
    &_inner {
      max-width: 720px;
      min-height: 600px;
      margin: 0 auto;
      padding: 25px 0 20px;
      h2 {
        margin: 40px auto;
        position: relative;
        padding: 0 10px;
        font-size: $fs-28;
        font-family: $font-family-yugo;
        font-weight: 500;
        color: #033490;
        padding: 52px 30px 56px;
        border-right: 1px solid #033490;
        border-left: 1px solid #033490;
        line-height: 1;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 30px;
        }
        &:before {
          left: -10px;
          border-top: solid 1px #033490;
          top: 10px;
        }
        &:after {
          right: -10px;
          border-bottom: solid 1px #033490;
          bottom: 10px;
        }
      }
      img {
        max-width: 100%;
        height: auto;
        padding-bottom: 40px;
      }
      p {
        padding-bottom: 30px;
        &.medium {
          font-size: $fs-16;
        }
        &.large {
          font-size: $fs-20;
        }
      }
      h3 {
        font-size: $fs-22;
        font-family: $font-family-yugo;
        font-weight: 500;
        color: #033490;
        padding: 20px 0;
        border-top: 2px solid #033490;
        margin-bottom: 30px;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 60%;
          bottom: 0;
          left: 0;
          border-bottom: 1px #033490 solid;
          height: 1px;
        }
      }
      h4 {
        font-size: $fs-18;
        font-family: $font-family-yugo;
        font-weight: 500;
        padding: 10px 25px;
        background-color: #e6ebed;
        margin-bottom: 20px;
        border-radius: 4px;
      }
      table {
        width: 100%;
        margin-bottom: 50px;
        th,
        td {
          padding: 10px 0;
          text-align: center;
        }
        tr:first-child {
          color: #fff;
          th:nth-child(odd) {
            background-color: #3f4d69;
          }
          th:nth-child(even) {
            background-color: #406bb2;
          }
        }
        tr:nth-child(odd) {
          td:nth-child(odd) {
            background-color: #e6ebed;
          }
          td:nth-child(even) {
            background-color: #f7f7f7;
          }
        }
        tr:nth-child(even) {
          td:nth-child(odd) {
            background-color: #f7f7f7;
          }
          td:nth-child(even) {
            background-color: #fff;
          }
        }
      }
      ul {
        margin-bottom: 30px;
        li {
          list-style-image: url(../img/ico_list.png);
          margin-left: 20px;
          margin-bottom: 10px;
        }
      }
      ol {
        margin-bottom: 30px;
        li {
          list-style: decimal;
          list-style-position: inside;
          margin-bottom: 10px;
          font-size: $fs-12;
        }
      }
      .btn_link {
        margin: 50px 0;
        a {
          display: block;
          color: #033490;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #033490;
          padding: 15px;
          text-align: center;
          text-decoration: none;
          &:hover {
            color: #fff;
            background-color: #033490;
            transition: all .3s;
          }
        }
      }
			em {
				font-style: oblique;
			}
			strong, b {
				font-weight: bold;
			}
    }
    .pager_inner {
      max-width: 720px;
      margin: 0 auto;
      padding: 0 0 120px;
      .pager_cont {
        @include clearfix;
        padding: 10px 0;
        border-top: 1px $cl-gray solid;
        border-bottom: 1px $cl-gray solid;
      }
      a {
        &.prev {
          float: left;
        }
        &.next {
          float: right;
        }
        img {
          width: 80px;
          height: auto;
          display: inline-block;
          vertical-align: middle;
        }
        p {
          width: 210px;
          line-height: 1.4;
          padding: 0 8px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    /* =============== form =============== */
    .form_cont {
      padding-top: 30px;
      .req {
        display: inline-block;
        vertical-align: top;
        font-size: $fs-10;
        background: $cl-lightBlue;
        color: #fff;
        padding: 0 5px 1px;
        margin: 3px 5px 3px 0;
      }
      form {
        table.form_tbl {
          width: 100%;
          border-top: 1px $cl-lightGray solid;
          margin-bottom: 10px;
          tr {
            th,
            td {
              color: $color;
              text-align: left;
              padding: 16px 20px;
              background: #fff !important;
              border-bottom: 1px $cl-lightGray solid;
            }
            th {
              width: 30%;
              border-right: 1px $cl-lightGray solid;
            }
            td {
              width: 70%;
              input[type="text"],
              input[type="email"],
              textarea {
                width: 100%;
                padding: 12px 14px;
                background: $cl-lightGrayLight;
                border: none;
                border-radius: 4px;
                font-family: $font-family;
              }
              input[type="text"].addnum {
                width: 30%;
                margin-left: 10px;
              }
              #ddArea {
                border: 2px #ccc dotted;
                text-align: center;
                background: $cl-lightGrayLight;
                padding: 30px;
                position: relative;
                p {
                  color: $cl-gray;
                  padding-bottom: 0;
                }
                .dd_info {
                  font-size: $fs-16;
                }
                label {
                  display: block;
                  width: 50%;
                  margin: 10px auto;
                  color: $cl-gray;
                  background-color: #fff;
                  border-radius: 4px;
                  border: 1px solid #ccc;
                  padding: 8px 10px;
                  text-align: center;
                  text-decoration: none;
                  cursor: pointer;
                  position: relative;
                  z-index: 2;
                  &:hover {
                    background-color: $cl-lightGrayLight;
                    border: 1px solid #ccc;
                  }
                  input[type="file"] {
                    display: none;
                  }
                }
                .dd_btn {
                  input[type="file"] {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    cursor: pointer;
                  }
                }
                .min {
                  padding-top: 10px;
                  font-size: $fs-12;
                }
              }
              textarea {
                height: 160px;
              }
              .caution {
                font-size: $fs-13;
                color: $cl-gray;
              }
            }
          }
        }
      }
      .cancel, .delete {
        display: block;
        text-align: right;
        cursor: pointer;
      }
      button {
        display: block;
        color: #e77302;
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #e77302;
        padding: 15px;
        text-align: center;
        text-decoration: none;
        width: 70%;
        font-family: $font-family;
        cursor: pointer;
        margin: 20px auto 50px;
        &:hover {
          color: #fff;
          background-color: #e77302;
          transition: all .3s;
        }
      }
    }
  }
}

/*
 PC archive
---------------------------------------- */

.archive {
  .cont_blog {
    width: 25%;
    background: url(../img/bg-wh.png);
    position: relative;
    float: left;
    @include heightMatch;
    &_box {
      @include heightMatchCont;
      padding: 7px;
      color: $cl-lightBlue;
      .blog_main {
        position: relative;
        z-index: 2;
        .blog_tit {
          height: 3em;
          box-sizing: content-box;
          overflow: hidden;
          font-size: $fs-18;
          line-height: 1.6;
          padding: 6% 4% 4%;
        }
        .blog_date {
          font-family: $font-family-lato;
          font-size: $fs-13;
          font-weight: 300;
          padding: 0 4%;
        }
      }
      .blog_img {
        width: 100%;
        height: 60%;
        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        img {
          width: 100%;
          height: auto;
          overflow: hidden;
          text-align: center;
          margin: 0 auto;
        }
      }
      .blog_nav {
        position: absolute;
        bottom: 10%;
        right: 10%;
        a {
          color: $cl-lightBlue;
        }
      }
    }
  }
  a .cont_blog {
    background: $cl-lightGrayLight;
    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99;
    }
  }
  a:nth-child(3n) .cont_blog {
    background: $cl-lightGray;
    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99;
    }
  }
  a:nth-child(3n+1) .cont_blog {
    background: #fff;
    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99;
    }
  }
  .link_bnr {
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-wh.png) no-repeat;
      background-size: contain;
      z-index: 99;
    }
    &:hover {
      .hv-am {
        top: -25%;
        left: -25%;
      }
    }
    .hv-am {
      position: absolute;
      top: 100%;
      left: 100%;
      z-index: 2;
      width: 150%;
      height: 150%;
      transform: rotate(-45deg);
      background: rgba(28, 108, 192, 0.5);
      -webkit-transition: .6s;
      transition: .6s;
      opacity: 1;
    }
  }
}

.contest_result {
  .main_inner {
    .result_tit {
      padding-top: 20px;
    }
    .result_txt01 {
      font-size: $fs-16;
      font-weight: bolder;
      text-align: center;
      padding-bottom: 50px;
    }
    .result_txt02 {
      width: 620px;
      margin: 0 auto;
      text-align: center;
      background: #fff;
      border-radius: 4px;
      padding: 10px;
      border: 1px #ddd solid;
      margin-bottom: 80px;
    }
    .result_txt03 {
      margin-bottom: 40px;
    }
    .result_img01 img {
      padding-bottom: 0;
    }
    .result_img02 img {
      padding-bottom: 20px;
    }
    .result_img03 {
      margin: 0 auto 50px;
      text-align: center;
      a { display: inline-block; }
      img {
        padding-bottom: 5px;
        margin: 0 auto;
      }
      p {
        font-size: $fs-12;
        color: #666;
      }
    }
  }
}