@charset "UTF-8";
@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(//fonts.googleapis.com/css?family=Lato:100,300,400,700,900);
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/*
  reset
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

/*
  base
--------------------------------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

img {
  border-style: none;
  display: block;
  vertical-align: bottom; }

a,
a img,
button {
  transition: .4s; }

a:link {
  color: #1c6cc0;
  text-decoration: none; }

a:visited {
  color: #1c6cc0; }

a:hover {
  color: #406bb2;
  text-decoration: underline; }

a:active {
  color: #666; }

.center {
  text-align: center; }

.t_left {
  text-align: left; }

.t_right {
  text-align: right; }

.mb_10 {
  margin-bottom: 10px; }

.mb_20 {
  margin-bottom: 20px; }

.mb_30 {
  margin-bottom: 30px; }

.mb_40 {
  margin-bottom: 40px; }

.mb_50 {
  margin-bottom: 50px; }

.mb_60 {
  margin-bottom: 60px; }

.mb_70 {
  margin-bottom: 70px; }

.mb_80 {
  margin-bottom: 80px; }

.mb_90 {
  margin-bottom: 90px; }

.mb_100 {
  margin-bottom: 100px; }

.mt_10 {
  margin-top: 10px; }

.mt_20 {
  margin-top: 20px; }

.mt_30 {
  margin-top: 30px; }

.mt_40 {
  margin-top: 40px; }

.mt_50 {
  margin-top: 50px; }

.mt_60 {
  margin-top: 60px; }

.mt_70 {
  margin-top: 70px; }

.mt_80 {
  margin-top: 80px; }

.mt_90 {
  margin-top: 90px; }

.mt_100 {
  margin-top: 100px; }

.pb_10 {
  padding-bottom: 10px; }

.pb_20 {
  padding-bottom: 20px; }

.pb_30 {
  padding-bottom: 30px; }

.pb_40 {
  padding-bottom: 40px; }

.pb_50 {
  padding-bottom: 50px; }

.pb_60 {
  padding-bottom: 60px; }

.pb_70 {
  padding-bottom: 70px; }

.pb_80 {
  padding-bottom: 80px; }

.pb_90 {
  padding-bottom: 90px; }

.pb_100 {
  padding-bottom: 100px; }

.pt_10 {
  padding-top: 10px; }

.pt_20 {
  padding-top: 20px; }

.pt_30 {
  padding-top: 30px; }

.pt_40 {
  padding-top: 40px; }

.pt_50 {
  padding-top: 50px; }

.pt_60 {
  padding-top: 60px; }

.pt_70 {
  padding-top: 70px; }

.pt_80 {
  padding-top: 80px; }

.pt_90 {
  padding-top: 90px; }

.pt_100 {
  padding-top: 100px; }

@media (min-width: 1025px) {
  /*
 PC common
---------------------------------------- */
  .sp-on {
    display: none; }
  .pc-on {
    display: block; }
  body {
    font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 200;
    color: #666;
    position: relative;
    margin: 0 auto;
    background: #e6ebed;
    min-width: 1080px; }
  /* =============== menu =============== */
  body.open .header_nav_btn img {
    -webkit-transition: all .5s;
    transition: all .5s;
    height: 24px; }
    body.open .header_nav_btn img.open {
      display: none; }
    body.open .header_nav_btn img.clone {
      display: block; }
  body.open .header_nav_menu {
    -webkit-transition: all .5s;
    transition: all .5s;
    visibility: visible;
    opacity: 1; }
  /* =============== header =============== */
  .header {
    width: 100%;
    position: absolute;
    z-index: 99999; }
    .header_nav_btn {
      position: fixed;
      padding: 24px 20px;
      display: inline-block;
      vertical-align: top;
      z-index: 15;
      cursor: pointer; }
      .header_nav_btn img.clone {
        display: none; }
    .header_nav_menu {
      position: fixed;
      display: table;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(2, 50, 141, 0.9);
      -webkit-transition: all .5s;
      transition: all .5s;
      visibility: hidden;
      opacity: 0;
      z-index: 5; }
      .header_nav_menu ul {
        display: table-cell;
        vertical-align: middle;
        text-align: center; }
        .header_nav_menu ul li {
          padding: 5px 0; }
          .header_nav_menu ul li a {
            text-decoration: none;
            font-size: 0.92em;
            color: #fff;
            padding: 10px 0;
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
            .header_nav_menu ul li a:hover {
              color: #1c6cc0; }
            .header_nav_menu ul li a span {
              display: block;
              font-family: "Lato", sans-serif;
              font-size: 30px;
              line-height: 30px;
              font-weight: 300;
              padding-bottom: 20px; }
            .header_nav_menu ul li a:hover {
              color: #888; }
    .header_logo {
      display: inline-block;
      padding: 24px 0 0 80px;
      vertical-align: top; }
    .header_tit {
      color: #fff;
      font-size: 0.71em;
      display: inline-block;
      padding: 26px 15px; }
    .header_cv_btn {
      text-align: center;
      position: fixed;
      transition: .3s;
      top: 0;
      right: 0; }
      .header_cv_btn a {
        width: 130px;
        height: 130px;
        display: block;
        background: #02328d;
        background: -moz-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        background: -webkit-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        background: linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02328d', endColorstr='#0f64ff',GradientType=1 ); }
        .header_cv_btn a img {
          text-align: center;
          margin: 0 auto;
          padding: 26px 0 14px; }
        .header_cv_btn a span {
          color: #fff;
          font-size: 0.92em; }
        .header_cv_btn a:hover {
          background: #0f64ff;
          background: -moz-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          background: -webkit-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          background: linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f64ff', endColorstr='#02328d',GradientType=1 ); }
  /* =============== footer =============== */
  .footer {
    *zoom: 1;
    border-top: 1px #ddd solid;
    padding: 0 3% 20px;
    background: #fff; }
    .footer:after {
      content: " ";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
    .footer_cont {
      float: left; }
      .footer_cont .footer_nav {
        padding: 20px 0 10px; }
        .footer_cont .footer_nav li {
          display: inline-block;
          font-size: 0.85em;
          letter-spacing: .05em; }
          .footer_cont .footer_nav li:after {
            content: ' ｜ '; }
          .footer_cont .footer_nav li:last-child:after {
            content: none; }
      .footer_cont .copyright {
        font-family: "Lato", sans-serif;
        font-size: 0.71em;
        font-weight: 500;
        letter-spacing: .05em;
        padding-bottom: 20px; }
    .footer_bnr {
      float: right;
      padding: 20px 0; }
  /* ============ moving ============ */
  .move_cont, .page .main h2, .page .main img, .page .main h3, .page .main h4, .page .main ul, .page .main ol, .page .main p, .page .main div, .page .main table {
    opacity: 0;
    transform: translate(0, 60px);
    -webkit-transform: translate(0, 60px);
    transition: .8s; }
  .moving {
    opacity: 1.0 !important;
    transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important; }
  .main form table p, .main form table div {
    opacity: inherit !important;
    transform: none !important;
    -webkit-transform: none !important;
    transition: inherit !important; }
  /*
 PC index
---------------------------------------- */
  .home {
    /* =============== hero =============== */
    /* =============== main =============== */ }
    .home .gray {
      display: none; }
    .home .hero {
      width: 100%;
      height: 95vh;
      max-height: 680px;
      background: #3f4d69 url(../img/hero_img.png) repeat-x center;
      color: #fff;
      position: relative; }
      .home .hero_cont {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: 100px;
        padding-left: 3%; }
        .home .hero_cont .hero_txt {
          padding-bottom: 30px;
          width: 50%; }
        .home .hero_cont .hero_copy {
          font-size: 30px;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 300; }
      .home .hero .border01 {
        width: 25%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
      .home .hero .border02 {
        width: 50%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
      .home .hero .border03 {
        width: 75%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
    .home .main {
      *zoom: 1;
      padding: 3%; }
      .home .main:after {
        content: " ";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .home .main .area_left {
        width: 50%;
        float: left; }
        .home .main .area_left .cont_large {
          width: 75%;
          background: #033490;
          position: relative;
          float: left; }
          .home .main .area_left .cont_large:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_large_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; }
            .home .main .area_left .cont_large_box .tit {
              color: #fff;
              font-size: 70px;
              font-weight: 100;
              font-family: "Lato", sans-serif;
              line-height: 1em;
              padding: 50px 30px 20px;
              position: relative;
              z-index: 3; }
              .home .main .area_left .cont_large_box .tit span {
                font-weight: 900; }
            .home .main .area_left .cont_large_box .tit_sub {
              color: #fff;
              font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
              font-weight: 300;
              font-size: 1.07em;
              padding: 0 30px;
              position: relative;
              z-index: 3; }
            .home .main .area_left .cont_large_box img {
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 1;
              transform: translate(-50%, -50%); }
          .home .main .area_left .cont_large .border04 {
            width: 66.7%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            border-right: 1px rgba(255, 255, 255, 0.4) solid; }
          .home .main .area_left .cont_large .border05 {
            width: 100%;
            height: 66.7%;
            position: absolute;
            top: 0;
            z-index: 1;
            border-bottom: 1px rgba(255, 255, 255, 0.4) solid; }
        .home .main .area_left .cont_min-img_01 {
          width: 25%;
          float: left; }
          .home .main .area_left .cont_min-img_01 img {
            width: 100%;
            height: auto; }
        .home .main .area_left .cont_info {
          width: 50%;
          background: url(../img/bg-bl.png);
          position: relative;
          float: left; }
          .home .main .area_left .cont_info:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_info_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 10%;
            color: #fff; }
            .home .main .area_left .cont_info_box .tit {
              font-family: "Lato", sans-serif;
              font-weight: 300;
              font-size: 22px; }
            .home .main .area_left .cont_info_box .tit_sub {
              font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
              font-weight: 300;
              font-size: 0.92em; }
              .home .main .area_left .cont_info_box .tit_sub:after {
                content: '';
                display: block;
                width: 60px;
                height: 2px;
                background: #fff;
                margin-top: 10px; }
            .home .main .area_left .cont_info_box .info_main {
              position: relative;
              padding-top: 10%; }
              .home .main .area_left .cont_info_box .info_main .info_tit {
                font-size: 1.42em;
                height: 3.2em;
                overflow: hidden; }
              .home .main .area_left .cont_info_box .info_main .info_date {
                font-family: "Lato", sans-serif;
                font-size: 0.92em;
                font-weight: 300;
                padding-top: 10%; }
            .home .main .area_left .cont_info_box .info_nav {
              position: absolute;
              bottom: 10%;
              right: 10%; }
              .home .main .area_left .cont_info_box .info_nav a {
                color: #fff; }
        .home .main .area_left .cont_img {
          width: 50%;
          overflow: hidden;
          background: #f4b318;
          background: -moz-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          background: -webkit-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          background: linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4b318', endColorstr='#e66c00',GradientType=1 );
          position: relative;
          float: left; }
          .home .main .area_left .cont_img:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_img_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 80%;
            margin: auto;
            display: table; }
            .home .main .area_left .cont_img_box img {
              max-width: 100%;
              padding: 10px;
              margin: 0 auto;
              text-align: center;
              position: relative;
              -moz-transition: -moz-transform 0.3s linear;
              -webkit-transition: -webkit-transform 0.3s linear;
              -o-transition: -o-transform 0.3s linear;
              -ms-transition: -ms-transform 0.3s linear;
              transition: transform 0.3s linear; }
              .home .main .area_left .cont_img_box img:hover {
                -webkit-transform: scale(1.1, 1.1);
                -moz-transform: scale(1.1, 1.1);
                -o-transform: scale(1.1, 1.1);
                -ms-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1); }
      .home .main .cont_page {
        width: 50%;
        background: #333;
        position: relative;
        float: left; }
        .home .main .cont_page:before {
          content: '';
          display: block;
          padding-top: 100%; }
        .home .main .cont_page_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #fff; }
          .home .main .cont_page_box .tit {
            font-family: "Lato", sans-serif;
            font-weight: 100;
            font-size: 50px;
            line-height: 1em;
            padding: 12% 10% 15px;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_box .tit_sub {
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
            font-weight: 300;
            font-size: 1.07em;
            padding: 0 10%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_box img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%); }
      .home .main .cont_page_02 {
        width: 50%;
        position: relative;
        float: left;
        background: #f7f7f7;
        border: 1px #fff solid; }
        .home .main .cont_page_02:before {
          content: '';
          display: block;
          padding-top: 100%; }
        .home .main .cont_page_02_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #333; }
          .home .main .cont_page_02_box .tit {
            color: #ccc;
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-size: 42px;
            line-height: 1em;
            padding: 5% 8% 5%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_02_box .tit_sub {
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
            font-weight: 500;
            font-size: 14px;
            padding: 0 8%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_02_box .img {
            width: 100%;
            height: 60%;
            overflow: hidden;
            text-align: center;
            margin: 0 auto;
            position: relative; }
            .home .main .cont_page_02_box .img img {
              width: 100%;
              height: auto;
              text-align: center;
              margin: 0 auto;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%); }
      .home .main .area_right {
        width: 50%;
        float: right; }
        .home .main .area_right .cont_blog {
          width: 50%;
          background: url(../img/bg-wh.png);
          position: relative;
          float: left; }
          .home .main .area_right .cont_blog:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_right .cont_blog_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 7px;
            color: #1c6cc0; }
            .home .main .area_right .cont_blog_box .blog_main {
              position: relative;
              z-index: 2; }
              .home .main .area_right .cont_blog_box .blog_main .blog_tit {
                height: 3em;
                box-sizing: content-box;
                overflow: hidden;
                font-size: 1.28em;
                line-height: 1.6;
                padding: 6% 4% 4%; }
              .home .main .area_right .cont_blog_box .blog_main .blog_date {
                font-family: "Lato", sans-serif;
                font-size: 0.92em;
                font-weight: 300;
                padding: 0 4%; }
            .home .main .area_right .cont_blog_box .blog_img {
              width: 100%;
              height: 60%;
              overflow: hidden;
              text-align: center;
              margin: 0 auto; }
              .home .main .area_right .cont_blog_box .blog_img img {
                width: 100%;
                height: auto;
                overflow: hidden;
                text-align: center;
                margin: 0 auto; }
            .home .main .area_right .cont_blog_box .blog_nav {
              position: absolute;
              bottom: 10%;
              right: 10%; }
              .home .main .area_right .cont_blog_box .blog_nav a {
                color: #1c6cc0; }
        .home .main .area_right a:nth-child(1) .cont_blog,
        .home .main .area_right a:nth-child(4) .cont_blog,
        .home .main .area_right a:nth-child(5) .cont_blog {
          background: #f7f7f7; }
          .home .main .area_right a:nth-child(1) .cont_blog:after,
          .home .main .area_right a:nth-child(4) .cont_blog:after,
          .home .main .area_right a:nth-child(5) .cont_blog:after {
            content: '';
            display: block;
            width: 70px;
            height: 70px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url(../img/arw-wh.png) no-repeat;
            background-size: contain;
            z-index: 99; }
        .home .main .area_right a:nth-child(2) .cont_blog,
        .home .main .area_right a:nth-child(3) .cont_blog {
          background: #fff; }
          .home .main .area_right a:nth-child(2) .cont_blog:after,
          .home .main .area_right a:nth-child(3) .cont_blog:after {
            content: '';
            display: block;
            width: 70px;
            height: 70px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url(../img/arw-gr.png) no-repeat;
            background-size: contain;
            z-index: 99; }
        .home .main .area_right .cont_min-img_02 {
          width: 100%; }
          .home .main .area_right .cont_min-img_02 img {
            float: left;
            width: 25%;
            height: auto; }
      .home .main .link_bnr {
        position: relative;
        overflow: hidden; }
        .home .main .link_bnr:after {
          content: '';
          display: block;
          width: 70px;
          height: 70px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(../img/arw-wh.png) no-repeat;
          background-size: contain;
          z-index: 99; }
        .home .main .link_bnr:hover .hv-am {
          top: -25%;
          left: -25%; }
        .home .main .link_bnr .hv-am {
          position: absolute;
          top: 100%;
          left: 100%;
          z-index: 2;
          width: 150%;
          height: 150%;
          transform: rotate(-45deg);
          background: rgba(28, 108, 192, 0.5);
          -webkit-transition: .6s;
          transition: .6s;
          opacity: 1; }
  /*
 PC page
---------------------------------------- */
  .page,
  .single,
  .archive {
    height: 100%;
    background: url(../img/page-side.png) repeat-y;
    /* =============== hero =============== */
    /* =============== main =============== */ }
    .page .main-area,
    .single .main-area,
    .archive .main-area {
      width: calc(100% - 221px);
      margin-left: 221px; }
    .page .white,
    .single .white,
    .archive .white {
      display: none; }
    .page .header_tit,
    .single .header_tit,
    .archive .header_tit {
      color: #888; }
    .page .hero,
    .single .hero,
    .archive .hero {
      width: 100%;
      height: 34vh;
      background: #3f4d69 url(../img/page-top.png) repeat-x center;
      background-size: auto 100%;
      color: #fff;
      position: relative; }
      .page .hero_cont,
      .single .hero_cont,
      .archive .hero_cont {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: 10px;
        padding-left: 40px; }
        .page .hero_cont .breadcrumb,
        .single .hero_cont .breadcrumb,
        .archive .hero_cont .breadcrumb {
          width: 75%;
          text-align: right;
          margin: auto; }
          .page .hero_cont .breadcrumb ul li,
          .single .hero_cont .breadcrumb ul li,
          .archive .hero_cont .breadcrumb ul li {
            display: inline-block;
            font-size: 0.85em;
            color: #fff; }
            .page .hero_cont .breadcrumb ul li a,
            .single .hero_cont .breadcrumb ul li a,
            .archive .hero_cont .breadcrumb ul li a {
              color: #fff; }
    .page.contest .hero,
    .single.contest .hero,
    .archive.contest .hero {
      background: #3f4d69 url(../img/contest_hero.png) repeat-x center;
      background-size: auto 100%; }
    .page.contest_result .hero,
    .single.contest_result .hero,
    .archive.contest_result .hero {
      background: #3f4d69 url(../img/result_bg.png) repeat-x center;
      background-size: auto 100%; }
    .page .main,
    .single .main,
    .archive .main {
      *zoom: 1;
      background-image: url(../img/bg-wh.png);
      min-height: 600px;
      /* =============== form =============== */ }
      .page .main:after,
      .single .main:after,
      .archive .main:after {
        content: " ";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .page .main_inner,
      .single .main_inner,
      .archive .main_inner {
        max-width: 720px;
        min-height: 600px;
        margin: 0 auto;
        padding: 25px 0 20px; }
        .page .main_inner h2,
        .single .main_inner h2,
        .archive .main_inner h2 {
          margin: 40px auto;
          position: relative;
          padding: 0 10px;
          font-size: 2em;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          color: #033490;
          padding: 52px 30px 56px;
          border-right: 1px solid #033490;
          border-left: 1px solid #033490;
          line-height: 1; }
          .page .main_inner h2:before, .page .main_inner h2:after,
          .single .main_inner h2:before,
          .single .main_inner h2:after,
          .archive .main_inner h2:before,
          .archive .main_inner h2:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 30px; }
          .page .main_inner h2:before,
          .single .main_inner h2:before,
          .archive .main_inner h2:before {
            left: -10px;
            border-top: solid 1px #033490;
            top: 10px; }
          .page .main_inner h2:after,
          .single .main_inner h2:after,
          .archive .main_inner h2:after {
            right: -10px;
            border-bottom: solid 1px #033490;
            bottom: 10px; }
        .page .main_inner img,
        .single .main_inner img,
        .archive .main_inner img {
          max-width: 100%;
          height: auto;
          padding-bottom: 40px; }
        .page .main_inner p,
        .single .main_inner p,
        .archive .main_inner p {
          padding-bottom: 30px; }
          .page .main_inner p.medium,
          .single .main_inner p.medium,
          .archive .main_inner p.medium {
            font-size: 1.14em; }
          .page .main_inner p.large,
          .single .main_inner p.large,
          .archive .main_inner p.large {
            font-size: 1.42em; }
        .page .main_inner h3,
        .single .main_inner h3,
        .archive .main_inner h3 {
          font-size: 1.57em;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          color: #033490;
          padding: 20px 0;
          border-top: 2px solid #033490;
          margin-bottom: 30px;
          position: relative; }
          .page .main_inner h3:after,
          .single .main_inner h3:after,
          .archive .main_inner h3:after {
            content: "";
            position: absolute;
            width: 60%;
            bottom: 0;
            left: 0;
            border-bottom: 1px #033490 solid;
            height: 1px; }
        .page .main_inner h4,
        .single .main_inner h4,
        .archive .main_inner h4 {
          font-size: 1.28em;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          padding: 10px 25px;
          background-color: #e6ebed;
          margin-bottom: 20px;
          border-radius: 4px; }
        .page .main_inner table,
        .single .main_inner table,
        .archive .main_inner table {
          width: 100%;
          margin-bottom: 50px; }
          .page .main_inner table th,
          .page .main_inner table td,
          .single .main_inner table th,
          .single .main_inner table td,
          .archive .main_inner table th,
          .archive .main_inner table td {
            padding: 10px 0;
            text-align: center; }
          .page .main_inner table tr:first-child,
          .single .main_inner table tr:first-child,
          .archive .main_inner table tr:first-child {
            color: #fff; }
            .page .main_inner table tr:first-child th:nth-child(odd),
            .single .main_inner table tr:first-child th:nth-child(odd),
            .archive .main_inner table tr:first-child th:nth-child(odd) {
              background-color: #3f4d69; }
            .page .main_inner table tr:first-child th:nth-child(even),
            .single .main_inner table tr:first-child th:nth-child(even),
            .archive .main_inner table tr:first-child th:nth-child(even) {
              background-color: #406bb2; }
          .page .main_inner table tr:nth-child(odd) td:nth-child(odd),
          .single .main_inner table tr:nth-child(odd) td:nth-child(odd),
          .archive .main_inner table tr:nth-child(odd) td:nth-child(odd) {
            background-color: #e6ebed; }
          .page .main_inner table tr:nth-child(odd) td:nth-child(even),
          .single .main_inner table tr:nth-child(odd) td:nth-child(even),
          .archive .main_inner table tr:nth-child(odd) td:nth-child(even) {
            background-color: #f7f7f7; }
          .page .main_inner table tr:nth-child(even) td:nth-child(odd),
          .single .main_inner table tr:nth-child(even) td:nth-child(odd),
          .archive .main_inner table tr:nth-child(even) td:nth-child(odd) {
            background-color: #f7f7f7; }
          .page .main_inner table tr:nth-child(even) td:nth-child(even),
          .single .main_inner table tr:nth-child(even) td:nth-child(even),
          .archive .main_inner table tr:nth-child(even) td:nth-child(even) {
            background-color: #fff; }
        .page .main_inner ul,
        .single .main_inner ul,
        .archive .main_inner ul {
          margin-bottom: 30px; }
          .page .main_inner ul li,
          .single .main_inner ul li,
          .archive .main_inner ul li {
            list-style-image: url(../img/ico_list.png);
            margin-left: 20px;
            margin-bottom: 10px; }
        .page .main_inner ol,
        .single .main_inner ol,
        .archive .main_inner ol {
          margin-bottom: 30px; }
          .page .main_inner ol li,
          .single .main_inner ol li,
          .archive .main_inner ol li {
            list-style: decimal;
            list-style-position: inside;
            margin-bottom: 10px;
            font-size: 0.85em; }
        .page .main_inner .btn_link,
        .single .main_inner .btn_link,
        .archive .main_inner .btn_link {
          margin: 50px 0; }
          .page .main_inner .btn_link a,
          .single .main_inner .btn_link a,
          .archive .main_inner .btn_link a {
            display: block;
            color: #033490;
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #033490;
            padding: 15px;
            text-align: center;
            text-decoration: none; }
            .page .main_inner .btn_link a:hover,
            .single .main_inner .btn_link a:hover,
            .archive .main_inner .btn_link a:hover {
              color: #fff;
              background-color: #033490;
              transition: all .3s; }
        .page .main_inner em,
        .single .main_inner em,
        .archive .main_inner em {
          font-style: oblique; }
        .page .main_inner strong, .page .main_inner b,
        .single .main_inner strong,
        .single .main_inner b,
        .archive .main_inner strong,
        .archive .main_inner b {
          font-weight: bold; }
      .page .main .pager_inner,
      .single .main .pager_inner,
      .archive .main .pager_inner {
        max-width: 720px;
        margin: 0 auto;
        padding: 0 0 120px; }
        .page .main .pager_inner .pager_cont,
        .single .main .pager_inner .pager_cont,
        .archive .main .pager_inner .pager_cont {
          *zoom: 1;
          padding: 10px 0;
          border-top: 1px #888 solid;
          border-bottom: 1px #888 solid; }
          .page .main .pager_inner .pager_cont:after,
          .single .main .pager_inner .pager_cont:after,
          .archive .main .pager_inner .pager_cont:after {
            content: " ";
            display: block;
            clear: both;
            height: 0;
            visibility: hidden; }
        .page .main .pager_inner a.prev,
        .single .main .pager_inner a.prev,
        .archive .main .pager_inner a.prev {
          float: left; }
        .page .main .pager_inner a.next,
        .single .main .pager_inner a.next,
        .archive .main .pager_inner a.next {
          float: right; }
        .page .main .pager_inner a img,
        .single .main .pager_inner a img,
        .archive .main .pager_inner a img {
          width: 80px;
          height: auto;
          display: inline-block;
          vertical-align: middle; }
        .page .main .pager_inner a p,
        .single .main .pager_inner a p,
        .archive .main .pager_inner a p {
          width: 210px;
          line-height: 1.4;
          padding: 0 8px;
          display: inline-block;
          vertical-align: middle; }
      .page .main .form_cont,
      .single .main .form_cont,
      .archive .main .form_cont {
        padding-top: 30px; }
        .page .main .form_cont .req,
        .single .main .form_cont .req,
        .archive .main .form_cont .req {
          display: inline-block;
          vertical-align: top;
          font-size: 0.71em;
          background: #1c6cc0;
          color: #fff;
          padding: 0 5px 1px;
          margin: 3px 5px 3px 0; }
        .page .main .form_cont form table.form_tbl,
        .single .main .form_cont form table.form_tbl,
        .archive .main .form_cont form table.form_tbl {
          width: 100%;
          border-top: 1px #e6ebed solid;
          margin-bottom: 10px; }
          .page .main .form_cont form table.form_tbl tr th,
          .page .main .form_cont form table.form_tbl tr td,
          .single .main .form_cont form table.form_tbl tr th,
          .single .main .form_cont form table.form_tbl tr td,
          .archive .main .form_cont form table.form_tbl tr th,
          .archive .main .form_cont form table.form_tbl tr td {
            color: #666;
            text-align: left;
            padding: 16px 20px;
            background: #fff !important;
            border-bottom: 1px #e6ebed solid; }
          .page .main .form_cont form table.form_tbl tr th,
          .single .main .form_cont form table.form_tbl tr th,
          .archive .main .form_cont form table.form_tbl tr th {
            width: 30%;
            border-right: 1px #e6ebed solid; }
          .page .main .form_cont form table.form_tbl tr td,
          .single .main .form_cont form table.form_tbl tr td,
          .archive .main .form_cont form table.form_tbl tr td {
            width: 70%; }
            .page .main .form_cont form table.form_tbl tr td input[type="text"],
            .page .main .form_cont form table.form_tbl tr td input[type="email"],
            .page .main .form_cont form table.form_tbl tr td textarea,
            .single .main .form_cont form table.form_tbl tr td input[type="text"],
            .single .main .form_cont form table.form_tbl tr td input[type="email"],
            .single .main .form_cont form table.form_tbl tr td textarea,
            .archive .main .form_cont form table.form_tbl tr td input[type="text"],
            .archive .main .form_cont form table.form_tbl tr td input[type="email"],
            .archive .main .form_cont form table.form_tbl tr td textarea {
              width: 100%;
              padding: 12px 14px;
              background: #f7f7f7;
              border: none;
              border-radius: 4px;
              font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
            .page .main .form_cont form table.form_tbl tr td input[type="text"].addnum,
            .single .main .form_cont form table.form_tbl tr td input[type="text"].addnum,
            .archive .main .form_cont form table.form_tbl tr td input[type="text"].addnum {
              width: 30%;
              margin-left: 10px; }
            .page .main .form_cont form table.form_tbl tr td #ddArea,
            .single .main .form_cont form table.form_tbl tr td #ddArea,
            .archive .main .form_cont form table.form_tbl tr td #ddArea {
              border: 2px #ccc dotted;
              text-align: center;
              background: #f7f7f7;
              padding: 30px;
              position: relative; }
              .page .main .form_cont form table.form_tbl tr td #ddArea p,
              .single .main .form_cont form table.form_tbl tr td #ddArea p,
              .archive .main .form_cont form table.form_tbl tr td #ddArea p {
                color: #888;
                padding-bottom: 0; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .dd_info,
              .single .main .form_cont form table.form_tbl tr td #ddArea .dd_info,
              .archive .main .form_cont form table.form_tbl tr td #ddArea .dd_info {
                font-size: 1.14em; }
              .page .main .form_cont form table.form_tbl tr td #ddArea label,
              .single .main .form_cont form table.form_tbl tr td #ddArea label,
              .archive .main .form_cont form table.form_tbl tr td #ddArea label {
                display: block;
                width: 50%;
                margin: 10px auto;
                color: #888;
                background-color: #fff;
                border-radius: 4px;
                border: 1px solid #ccc;
                padding: 8px 10px;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                position: relative;
                z-index: 2; }
                .page .main .form_cont form table.form_tbl tr td #ddArea label:hover,
                .single .main .form_cont form table.form_tbl tr td #ddArea label:hover,
                .archive .main .form_cont form table.form_tbl tr td #ddArea label:hover {
                  background-color: #f7f7f7;
                  border: 1px solid #ccc; }
                .page .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"],
                .single .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"],
                .archive .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"] {
                  display: none; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"],
              .single .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"],
              .archive .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"] {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                cursor: pointer; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .min,
              .single .main .form_cont form table.form_tbl tr td #ddArea .min,
              .archive .main .form_cont form table.form_tbl tr td #ddArea .min {
                padding-top: 10px;
                font-size: 0.85em; }
            .page .main .form_cont form table.form_tbl tr td textarea,
            .single .main .form_cont form table.form_tbl tr td textarea,
            .archive .main .form_cont form table.form_tbl tr td textarea {
              height: 160px; }
            .page .main .form_cont form table.form_tbl tr td .caution,
            .single .main .form_cont form table.form_tbl tr td .caution,
            .archive .main .form_cont form table.form_tbl tr td .caution {
              font-size: 0.92em;
              color: #888; }
        .page .main .form_cont .cancel, .page .main .form_cont .delete,
        .single .main .form_cont .cancel,
        .single .main .form_cont .delete,
        .archive .main .form_cont .cancel,
        .archive .main .form_cont .delete {
          display: block;
          text-align: right;
          cursor: pointer; }
        .page .main .form_cont button,
        .single .main .form_cont button,
        .archive .main .form_cont button {
          display: block;
          color: #e77302;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #e77302;
          padding: 15px;
          text-align: center;
          text-decoration: none;
          width: 70%;
          font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          cursor: pointer;
          margin: 20px auto 50px; }
          .page .main .form_cont button:hover,
          .single .main .form_cont button:hover,
          .archive .main .form_cont button:hover {
            color: #fff;
            background-color: #e77302;
            transition: all .3s; }
  /*
 PC archive
---------------------------------------- */
  .archive .cont_blog {
    width: 25%;
    background: url(../img/bg-wh.png);
    position: relative;
    float: left; }
    .archive .cont_blog:before {
      content: '';
      display: block;
      padding-top: 100%; }
    .archive .cont_blog_box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 7px;
      color: #1c6cc0; }
      .archive .cont_blog_box .blog_main {
        position: relative;
        z-index: 2; }
        .archive .cont_blog_box .blog_main .blog_tit {
          height: 3em;
          box-sizing: content-box;
          overflow: hidden;
          font-size: 1.28em;
          line-height: 1.6;
          padding: 6% 4% 4%; }
        .archive .cont_blog_box .blog_main .blog_date {
          font-family: "Lato", sans-serif;
          font-size: 0.92em;
          font-weight: 300;
          padding: 0 4%; }
      .archive .cont_blog_box .blog_img {
        width: 100%;
        height: 60%;
        overflow: hidden;
        text-align: center;
        margin: 0 auto; }
        .archive .cont_blog_box .blog_img img {
          width: 100%;
          height: auto;
          overflow: hidden;
          text-align: center;
          margin: 0 auto; }
      .archive .cont_blog_box .blog_nav {
        position: absolute;
        bottom: 10%;
        right: 10%; }
        .archive .cont_blog_box .blog_nav a {
          color: #1c6cc0; }
  .archive a .cont_blog {
    background: #f7f7f7; }
    .archive a .cont_blog:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive a:nth-child(3n) .cont_blog {
    background: #e6ebed; }
    .archive a:nth-child(3n) .cont_blog:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive a:nth-child(3n+1) .cont_blog {
    background: #fff; }
    .archive a:nth-child(3n+1) .cont_blog:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive .link_bnr {
    position: relative;
    overflow: hidden; }
    .archive .link_bnr:after {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-wh.png) no-repeat;
      background-size: contain;
      z-index: 99; }
    .archive .link_bnr:hover .hv-am {
      top: -25%;
      left: -25%; }
    .archive .link_bnr .hv-am {
      position: absolute;
      top: 100%;
      left: 100%;
      z-index: 2;
      width: 150%;
      height: 150%;
      transform: rotate(-45deg);
      background: rgba(28, 108, 192, 0.5);
      -webkit-transition: .6s;
      transition: .6s;
      opacity: 1; }
  .contest_result .main_inner .result_tit {
    padding-top: 20px; }
  .contest_result .main_inner .result_txt01 {
    font-size: 1.14em;
    font-weight: bolder;
    text-align: center;
    padding-bottom: 50px; }
  .contest_result .main_inner .result_txt02 {
    width: 620px;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    border: 1px #ddd solid;
    margin-bottom: 80px; }
  .contest_result .main_inner .result_txt03 {
    margin-bottom: 40px; }
  .contest_result .main_inner .result_img01 img {
    padding-bottom: 0; }
  .contest_result .main_inner .result_img02 img {
    padding-bottom: 20px; }
  .contest_result .main_inner .result_img03 {
    margin: 0 auto 50px;
    text-align: center; }
    .contest_result .main_inner .result_img03 a {
      display: inline-block; }
    .contest_result .main_inner .result_img03 img {
      padding-bottom: 5px;
      margin: 0 auto; }
    .contest_result .main_inner .result_img03 p {
      font-size: 0.85em;
      color: #666; } }

@media (min-width: 641px) and (max-width: 1024px) {
  /*
 TB common
---------------------------------------- */
  .sp-on {
    display: block; }
  .pc-on {
    display: none; }
  body {
    font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 200;
    color: #666;
    position: relative;
    background: #3f4d69 url(../img/bg-bl.png); }
  /* =============== menu =============== */
  body.open .header_nav_btn img {
    -webkit-transition: all .5s;
    transition: all .5s;
    height: 24px; }
    body.open .header_nav_btn img.open {
      display: none; }
    body.open .header_nav_btn img.clone {
      display: block; }
  body.open .header_nav_menu {
    -webkit-transition: all .5s;
    transition: all .5s;
    visibility: visible;
    opacity: 1; }
  /* =============== header =============== */
  .header {
    width: 100%;
    position: absolute;
    z-index: 99999; }
    .header_nav_btn {
      position: fixed;
      padding: 24px 20px;
      display: inline-block;
      vertical-align: top;
      z-index: 15;
      cursor: pointer; }
      .header_nav_btn img.clone {
        display: none; }
    .header_nav_menu {
      position: fixed;
      display: table;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(2, 50, 141, 0.9);
      -webkit-transition: all .5s;
      transition: all .5s;
      visibility: hidden;
      opacity: 0;
      z-index: 5; }
      .header_nav_menu ul {
        display: table-cell;
        vertical-align: middle;
        text-align: center; }
        .header_nav_menu ul li {
          padding: 5px 0; }
          .header_nav_menu ul li a {
            text-decoration: none;
            font-size: 0.92em;
            color: #fff;
            padding: 10px 0;
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
            .header_nav_menu ul li a:hover {
              color: #1c6cc0; }
            .header_nav_menu ul li a span {
              display: block;
              font-family: "Lato", sans-serif;
              font-size: 30px;
              line-height: 30px;
              font-weight: 300;
              padding-bottom: 20px; }
            .header_nav_menu ul li a:hover {
              color: #888; }
    .header_logo {
      display: inline-block;
      padding: 24px 0 0 80px;
      vertical-align: top; }
    .header_tit {
      color: #fff;
      font-size: 0.71em;
      display: inline-block;
      padding: 26px 15px; }
    .header_cv_btn {
      text-align: center;
      position: fixed;
      transition: .3s;
      top: 0;
      right: 0; }
      .header_cv_btn a {
        width: 130px;
        height: 130px;
        display: block;
        background: #02328d;
        background: -moz-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        background: -webkit-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        background: linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02328d', endColorstr='#0f64ff',GradientType=1 ); }
        .header_cv_btn a img {
          text-align: center;
          margin: 0 auto;
          padding: 26px 0 14px; }
        .header_cv_btn a span {
          color: #fff;
          font-size: 0.92em; }
        .header_cv_btn a:hover {
          background: #0f64ff;
          background: -moz-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          background: -webkit-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          background: linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f64ff', endColorstr='#02328d',GradientType=1 ); }
  /* =============== footer =============== */
  .footer {
    border-top: 1px #ddd solid;
    padding: 0 3% 10px;
    background: #fff; }
    .footer_cont .footer_nav {
      padding: 20px 0 15px; }
      .footer_cont .footer_nav li {
        display: inline-block;
        font-size: 0.78em;
        letter-spacing: .05em; }
        .footer_cont .footer_nav li:after {
          content: ' ｜ '; }
        .footer_cont .footer_nav li:last-child:after {
          content: none; }
    .footer_cont .copyright {
      font-family: "Lato", sans-serif;
      font-size: 0.71em;
      font-weight: 500;
      letter-spacing: .05em; }
    .footer_bnr {
      padding: 10px 0 30px; }
      .footer_bnr img {
        width: auto;
        height: 40px; }
  /* ============ moving ============ */
  .move_cont, .page .main h2, .page .main img, .page .main h3, .page .main h4, .page .main ul, .page .main ol, .page .main p, .page .main div, .page .main table {
    opacity: 0;
    transform: translate(0, 60px);
    -webkit-transform: translate(0, 60px);
    transition: .8s; }
  .moving {
    opacity: 1.0 !important;
    transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important; }
  .main form table p, .main form table div {
    opacity: inherit !important;
    transform: none !important;
    -webkit-transform: none !important;
    transition: inherit !important; }
  /*
 TB index
---------------------------------------- */
  .home {
    /* =============== hero =============== */
    /* =============== main =============== */ }
    .home .gray {
      display: none; }
    .home .hero {
      width: 100%;
      height: 65vh;
      background: #3f4d69 url(../img/hero_img.png) no-repeat;
      background-size: cover;
      color: #fff;
      position: relative; }
      .home .hero_cont {
        width: 100%;
        position: relative;
        z-index: 10; }
        .home .hero_cont .hero_txt {
          font-size: 0.92em;
          padding: 36vh 4% 20px;
          width: 70%; }
        .home .hero_cont .hero_copy {
          font-size: 30px;
          line-height: 1.4;
          padding: 0 4%;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 300; }
      .home .hero .border01 {
        width: 25%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
      .home .hero .border02 {
        width: 50%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
      .home .hero .border03 {
        width: 75%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
    .home .main {
      *zoom: 1; }
      .home .main:after {
        content: " ";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .home .main .area_left {
        width: 100%; }
        .home .main .area_left .cont_large {
          width: 75%;
          background: #033490;
          position: relative;
          float: left; }
          .home .main .area_left .cont_large:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_large_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; }
            .home .main .area_left .cont_large_box .tit {
              color: #fff;
              font-size: 66px;
              font-weight: 100;
              font-family: "Lato", sans-serif;
              line-height: 1em;
              padding: 50px 30px 10px;
              position: relative;
              z-index: 3; }
              .home .main .area_left .cont_large_box .tit span {
                font-weight: 900; }
            .home .main .area_left .cont_large_box .tit_sub {
              color: #fff;
              font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
              font-weight: 300;
              font-size: 0.92em;
              padding: 0 30px;
              position: relative;
              z-index: 3; }
            .home .main .area_left .cont_large_box img {
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 1;
              transform: translate(-50%, -50%); }
          .home .main .area_left .cont_large .border04 {
            width: 66.7%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            border-right: 1px rgba(255, 255, 255, 0.4) solid; }
          .home .main .area_left .cont_large .border05 {
            width: 100%;
            height: 66.7%;
            position: absolute;
            top: 0;
            z-index: 1;
            border-bottom: 1px rgba(255, 255, 255, 0.4) solid; }
        .home .main .area_left .cont_min-img_01 {
          width: 25%;
          float: left; }
          .home .main .area_left .cont_min-img_01 img {
            float: left;
            width: 100%;
            height: auto; }
        .home .main .area_left .cont_info {
          width: 25%;
          background: url(../img/bg-bl.png);
          position: relative;
          float: left; }
          .home .main .area_left .cont_info:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_info_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 8%;
            color: #fff; }
            .home .main .area_left .cont_info_box .tit {
              font-family: "Lato", sans-serif;
              font-weight: 300;
              font-size: 0.92em;
              line-height: 1em;
              padding-bottom: 2px; }
            .home .main .area_left .cont_info_box .tit_sub {
              font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
              font-weight: 300;
              font-size: 0.71em; }
              .home .main .area_left .cont_info_box .tit_sub:after {
                content: '';
                display: block;
                width: 40px;
                height: 1px;
                background: #fff;
                margin-top: 2px; }
            .home .main .area_left .cont_info_box .info_main {
              position: relative;
              padding-top: 10px; }
              .home .main .area_left .cont_info_box .info_main .info_tit {
                font-size: 0.92em;
                height: 3.2em;
                overflow: hidden; }
              .home .main .area_left .cont_info_box .info_main .info_date {
                font-family: "Lato", sans-serif;
                font-size: 0.71em;
                font-weight: 300;
                padding-top: 10px; }
            .home .main .area_left .cont_info_box .info_nav {
              position: absolute;
              bottom: 8%;
              right: 8%; }
              .home .main .area_left .cont_info_box .info_nav a {
                font-size: 0.71em;
                color: #fff; }
        .home .main .area_left .cont_img {
          width: 25%;
          background: #f4b318;
          background: -moz-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          background: -webkit-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          background: linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4b318', endColorstr='#e66c00',GradientType=1 );
          position: relative;
          float: left; }
          .home .main .area_left .cont_img:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_img_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 80%;
            margin: auto;
            display: table; }
            .home .main .area_left .cont_img_box img {
              max-width: 100%;
              padding: 10px;
              margin: 0 auto;
              text-align: center;
              position: relative;
              -moz-transition: -moz-transform 0.3s linear;
              -webkit-transition: -webkit-transform 0.3s linear;
              -o-transition: -o-transform 0.3s linear;
              -ms-transition: -ms-transform 0.3s linear;
              transition: transform 0.3s linear; }
              .home .main .area_left .cont_img_box img:hover {
                -webkit-transform: scale(1.1, 1.1);
                -moz-transform: scale(1.1, 1.1);
                -o-transform: scale(1.1, 1.1);
                -ms-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1); }
      .home .main .cont_page {
        width: 25%;
        background: #333;
        position: relative;
        float: left; }
        .home .main .cont_page:before {
          content: '';
          display: block;
          padding-top: 100%; }
        .home .main .cont_page_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #fff; }
          .home .main .cont_page_box .tit {
            font-family: "Lato", sans-serif;
            font-weight: 100;
            font-size: 22px;
            line-height: 1em;
            padding: 20px 8% 5px;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_box .tit_sub {
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
            font-weight: 300;
            font-size: 0.78em;
            padding: 0 8%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_box img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%); }
      .home .main .cont_page_02 {
        width: 25%;
        background: #333;
        position: relative;
        float: left;
        background: #f7f7f7;
        border: 1px #fff solid; }
        .home .main .cont_page_02:before {
          content: '';
          display: block;
          padding-top: 100%; }
        .home .main .cont_page_02_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #333; }
          .home .main .cont_page_02_box .tit {
            color: #ccc;
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-size: 22px;
            line-height: 1em;
            padding: 5% 8% 5%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_02_box .tit_sub {
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
            font-weight: 300;
            font-size: 0.78em;
            padding: 0 8%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_02_box .img {
            width: 100%;
            height: 60%;
            overflow: hidden;
            text-align: center;
            margin: 0 auto; }
            .home .main .cont_page_02_box .img img {
              width: 100%;
              height: auto;
              text-align: center;
              margin: 0 auto; }
      .home .main .area_right {
        width: 100%; }
        .home .main .area_right a:hover {
          color: #fff; }
        .home .main .area_right .cont_blog {
          width: 25%;
          background: url(../img/bg-wh.png);
          position: relative;
          float: left; }
          .home .main .area_right .cont_blog:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_right .cont_blog_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 7px;
            color: #1c6cc0; }
            .home .main .area_right .cont_blog_box .blog_main {
              position: relative;
              z-index: 2; }
              .home .main .area_right .cont_blog_box .blog_main .blog_tit {
                height: 3em;
                box-sizing: content-box;
                overflow: hidden;
                font-size: 1.28em;
                line-height: 1.6;
                padding: 5% 4% 2%;
                font-size: 0.92em; }
              .home .main .area_right .cont_blog_box .blog_main .blog_date {
                font-family: "Lato", sans-serif;
                font-size: 0.71em;
                font-weight: 300;
                padding: 0 4%; }
            .home .main .area_right .cont_blog_box .blog_img {
              width: 100%;
              height: 60%;
              overflow: hidden;
              text-align: center;
              margin: 0 auto; }
              .home .main .area_right .cont_blog_box .blog_img img {
                width: 100%;
                height: auto;
                overflow: hidden;
                text-align: center;
                margin: 0 auto; }
            .home .main .area_right .cont_blog_box .blog_nav {
              position: absolute;
              bottom: 8%;
              right: 8%; }
              .home .main .area_right .cont_blog_box .blog_nav a {
                font-size: 0.71em;
                color: #1c6cc0; }
        .home .main .area_right a:nth-child(2) .cont_blog,
        .home .main .area_right a:nth-child(4) .cont_blog {
          background: #f7f7f7; }
          .home .main .area_right a:nth-child(2) .cont_blog:after,
          .home .main .area_right a:nth-child(4) .cont_blog:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url(../img/arw-wh.png) no-repeat;
            background-size: contain;
            z-index: 99; }
        .home .main .area_right a:nth-child(1) .cont_blog,
        .home .main .area_right a:nth-child(3) .cont_blog,
        .home .main .area_right a:nth-child(5) .cont_blog {
          background: #fff; }
          .home .main .area_right a:nth-child(1) .cont_blog:after,
          .home .main .area_right a:nth-child(3) .cont_blog:after,
          .home .main .area_right a:nth-child(5) .cont_blog:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url(../img/arw-gr.png) no-repeat;
            background-size: contain;
            z-index: 99; }
        .home .main .area_right .cont_min-img_02 {
          width: 100%; }
          .home .main .area_right .cont_min-img_02 img {
            float: left;
            width: 25%;
            height: auto; }
            .home .main .area_right .cont_min-img_02 img:nth-child(3), .home .main .area_right .cont_min-img_02 img:nth-child(4) {
              display: none; }
      .home .main .link_bnr {
        position: relative;
        overflow: hidden; }
        .home .main .link_bnr:after {
          content: '';
          display: block;
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(../img/arw-wh.png) no-repeat;
          background-size: contain;
          z-index: 99; }
        .home .main .link_bnr:hover .hv-am {
          top: -25%;
          left: -25%; }
        .home .main .link_bnr .hv-am {
          position: absolute;
          top: 100%;
          left: 100%;
          z-index: 2;
          width: 150%;
          height: 150%;
          transform: rotate(-45deg);
          background: rgba(28, 108, 192, 0.5);
          -webkit-transition: .6s;
          transition: .6s;
          opacity: 1; }
  /*
 TB page
---------------------------------------- */
  .page,
  .single,
  .archive {
    /* =============== hero =============== */
    /* =============== main =============== */ }
    .page .white,
    .single .white,
    .archive .white {
      display: none; }
    .page .header_tit,
    .single .header_tit,
    .archive .header_tit {
      color: #888; }
    .page .hero,
    .single .hero,
    .archive .hero {
      width: 100%;
      height: 34vh;
      background: #3f4d69 url(../img/page-top.png) repeat-x center;
      background-size: auto 100%;
      color: #fff;
      position: relative; }
      .page .hero_cont,
      .single .hero_cont,
      .archive .hero_cont {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: 10px; }
        .page .hero_cont .breadcrumb,
        .single .hero_cont .breadcrumb,
        .archive .hero_cont .breadcrumb {
          max-width: 720px;
          text-align: right;
          margin: 0 auto; }
          .page .hero_cont .breadcrumb ul li,
          .single .hero_cont .breadcrumb ul li,
          .archive .hero_cont .breadcrumb ul li {
            display: inline-block;
            font-size: 0.85em;
            color: #666; }
            .page .hero_cont .breadcrumb ul li a,
            .single .hero_cont .breadcrumb ul li a,
            .archive .hero_cont .breadcrumb ul li a {
              color: #999; }
    .page.contest .hero,
    .single.contest .hero,
    .archive.contest .hero {
      background: #3f4d69 url(../img/contest_hero.png) repeat-x center;
      background-size: auto 100%; }
    .page.contest_result .hero,
    .single.contest_result .hero,
    .archive.contest_result .hero {
      background: #3f4d69 url(../img/result_bg.png) repeat-x center;
      background-size: auto 100%; }
    .page .main,
    .single .main,
    .archive .main {
      *zoom: 1;
      background-image: url(../img/bg-wh.png);
      min-height: 600px;
      padding: 0 6%;
      /* =============== form =============== */ }
      .page .main:after,
      .single .main:after,
      .archive .main:after {
        content: " ";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .page .main_inner,
      .single .main_inner,
      .archive .main_inner {
        max-width: 720px;
        min-height: 600px;
        margin: 0 auto;
        padding: 30px 0 10px; }
        .page .main_inner h2,
        .single .main_inner h2,
        .archive .main_inner h2 {
          margin: 0 0 40px;
          position: relative;
          padding: 0 15px;
          font-size: 2em;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          color: #033490;
          padding: 50px 30px 54px;
          border-right: 1px solid #033490;
          border-left: 1px solid #033490;
          line-height: 1; }
          .page .main_inner h2:before, .page .main_inner h2:after,
          .single .main_inner h2:before,
          .single .main_inner h2:after,
          .archive .main_inner h2:before,
          .archive .main_inner h2:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 30px; }
          .page .main_inner h2:before,
          .single .main_inner h2:before,
          .archive .main_inner h2:before {
            left: -8px;
            border-top: solid 1px #033490;
            top: 8px; }
          .page .main_inner h2:after,
          .single .main_inner h2:after,
          .archive .main_inner h2:after {
            right: -8px;
            border-bottom: solid 1px #033490;
            bottom: 8px; }
        .page .main_inner img,
        .single .main_inner img,
        .archive .main_inner img {
          max-width: 100%;
          height: auto;
          padding-bottom: 30px; }
        .page .main_inner p,
        .single .main_inner p,
        .archive .main_inner p {
          padding-bottom: 30px; }
          .page .main_inner p.medium,
          .single .main_inner p.medium,
          .archive .main_inner p.medium {
            font-size: 1.14em; }
          .page .main_inner p.large,
          .single .main_inner p.large,
          .archive .main_inner p.large {
            font-size: 1.42em; }
        .page .main_inner h3,
        .single .main_inner h3,
        .archive .main_inner h3 {
          font-size: 1.57em;
          color: #033490;
          padding: 20px 0;
          border-top: 1px solid #033490;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          margin-bottom: 30px;
          position: relative; }
          .page .main_inner h3:after,
          .single .main_inner h3:after,
          .archive .main_inner h3:after {
            content: "";
            position: absolute;
            width: 60%;
            bottom: 0;
            left: 0;
            border-bottom: 1px #033490 solid;
            height: 1px; }
        .page .main_inner h4,
        .single .main_inner h4,
        .archive .main_inner h4 {
          font-size: 1.28em;
          padding: 10px 25px;
          background-color: #e6ebed;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          margin-bottom: 30px;
          border-radius: 4px; }
        .page .main_inner table,
        .single .main_inner table,
        .archive .main_inner table {
          width: 100%;
          margin-bottom: 50px; }
          .page .main_inner table th,
          .page .main_inner table td,
          .single .main_inner table th,
          .single .main_inner table td,
          .archive .main_inner table th,
          .archive .main_inner table td {
            padding: 10px 0;
            text-align: center; }
          .page .main_inner table tr:first-child,
          .single .main_inner table tr:first-child,
          .archive .main_inner table tr:first-child {
            color: #fff; }
            .page .main_inner table tr:first-child th:nth-child(odd),
            .single .main_inner table tr:first-child th:nth-child(odd),
            .archive .main_inner table tr:first-child th:nth-child(odd) {
              background-color: #3f4d69; }
            .page .main_inner table tr:first-child th:nth-child(even),
            .single .main_inner table tr:first-child th:nth-child(even),
            .archive .main_inner table tr:first-child th:nth-child(even) {
              background-color: #406bb2; }
          .page .main_inner table tr:nth-child(odd) td:nth-child(odd),
          .single .main_inner table tr:nth-child(odd) td:nth-child(odd),
          .archive .main_inner table tr:nth-child(odd) td:nth-child(odd) {
            background-color: #e6ebed; }
          .page .main_inner table tr:nth-child(odd) td:nth-child(even),
          .single .main_inner table tr:nth-child(odd) td:nth-child(even),
          .archive .main_inner table tr:nth-child(odd) td:nth-child(even) {
            background-color: #f7f7f7; }
          .page .main_inner table tr:nth-child(even) td:nth-child(odd),
          .single .main_inner table tr:nth-child(even) td:nth-child(odd),
          .archive .main_inner table tr:nth-child(even) td:nth-child(odd) {
            background-color: #f7f7f7; }
          .page .main_inner table tr:nth-child(even) td:nth-child(even),
          .single .main_inner table tr:nth-child(even) td:nth-child(even),
          .archive .main_inner table tr:nth-child(even) td:nth-child(even) {
            background-color: #fff; }
        .page .main_inner ul,
        .single .main_inner ul,
        .archive .main_inner ul {
          margin-bottom: 30px; }
          .page .main_inner ul li,
          .single .main_inner ul li,
          .archive .main_inner ul li {
            list-style-image: url(../img/ico_list.png);
            margin-left: 20px;
            margin-bottom: 10px; }
        .page .main_inner ol,
        .single .main_inner ol,
        .archive .main_inner ol {
          margin-bottom: 30px; }
          .page .main_inner ol li,
          .single .main_inner ol li,
          .archive .main_inner ol li {
            list-style: decimal;
            list-style-position: inside;
            margin-bottom: 10px;
            font-size: 0.85em; }
        .page .main_inner .btn_link,
        .single .main_inner .btn_link,
        .archive .main_inner .btn_link {
          margin: 50px 0; }
          .page .main_inner .btn_link a,
          .single .main_inner .btn_link a,
          .archive .main_inner .btn_link a {
            display: block;
            width: 100%;
            color: #033490;
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #033490;
            padding: 15px;
            text-align: center;
            text-decoration: none; }
            .page .main_inner .btn_link a:hover,
            .single .main_inner .btn_link a:hover,
            .archive .main_inner .btn_link a:hover {
              color: #fff;
              background-color: #033490;
              transition: all .3s; }
      .page .main .pager_inner,
      .single .main .pager_inner,
      .archive .main .pager_inner {
        max-width: 720px;
        margin: 0 auto;
        padding: 0 0 70px; }
        .page .main .pager_inner .pager_cont,
        .single .main .pager_inner .pager_cont,
        .archive .main .pager_inner .pager_cont {
          *zoom: 1;
          padding: 10px 0;
          border-top: 1px #888 solid;
          border-bottom: 1px #888 solid; }
          .page .main .pager_inner .pager_cont:after,
          .single .main .pager_inner .pager_cont:after,
          .archive .main .pager_inner .pager_cont:after {
            content: " ";
            display: block;
            clear: both;
            height: 0;
            visibility: hidden; }
        .page .main .pager_inner a.prev,
        .single .main .pager_inner a.prev,
        .archive .main .pager_inner a.prev {
          float: left; }
        .page .main .pager_inner a.next,
        .single .main .pager_inner a.next,
        .archive .main .pager_inner a.next {
          float: right; }
        .page .main .pager_inner a img,
        .single .main .pager_inner a img,
        .archive .main .pager_inner a img {
          width: 60px;
          height: auto;
          display: inline-block;
          vertical-align: middle; }
        .page .main .pager_inner a p,
        .single .main .pager_inner a p,
        .archive .main .pager_inner a p {
          width: 210px;
          line-height: 1.4;
          padding: 0 8px;
          display: inline-block;
          vertical-align: middle; }
      .page .main .form_cont,
      .single .main .form_cont,
      .archive .main .form_cont {
        padding-top: 30px; }
        .page .main .form_cont .req,
        .single .main .form_cont .req,
        .archive .main .form_cont .req {
          display: inline-block;
          vertical-align: top;
          font-size: 0.71em;
          background: #1c6cc0;
          color: #fff;
          padding: 0 5px 1px;
          margin: 3px 5px 3px 0; }
        .page .main .form_cont form table.form_tbl,
        .single .main .form_cont form table.form_tbl,
        .archive .main .form_cont form table.form_tbl {
          width: 100%;
          border-top: 1px #e6ebed solid;
          margin-bottom: 10px; }
          .page .main .form_cont form table.form_tbl tr th,
          .page .main .form_cont form table.form_tbl tr td,
          .single .main .form_cont form table.form_tbl tr th,
          .single .main .form_cont form table.form_tbl tr td,
          .archive .main .form_cont form table.form_tbl tr th,
          .archive .main .form_cont form table.form_tbl tr td {
            color: #666;
            text-align: left;
            padding: 16px 20px;
            background: #fff !important;
            border-bottom: 1px #e6ebed solid; }
          .page .main .form_cont form table.form_tbl tr th,
          .single .main .form_cont form table.form_tbl tr th,
          .archive .main .form_cont form table.form_tbl tr th {
            width: 30%;
            border-right: 1px #e6ebed solid; }
          .page .main .form_cont form table.form_tbl tr td,
          .single .main .form_cont form table.form_tbl tr td,
          .archive .main .form_cont form table.form_tbl tr td {
            width: 70%; }
            .page .main .form_cont form table.form_tbl tr td input[type="text"],
            .page .main .form_cont form table.form_tbl tr td input[type="email"],
            .page .main .form_cont form table.form_tbl tr td textarea,
            .single .main .form_cont form table.form_tbl tr td input[type="text"],
            .single .main .form_cont form table.form_tbl tr td input[type="email"],
            .single .main .form_cont form table.form_tbl tr td textarea,
            .archive .main .form_cont form table.form_tbl tr td input[type="text"],
            .archive .main .form_cont form table.form_tbl tr td input[type="email"],
            .archive .main .form_cont form table.form_tbl tr td textarea {
              width: 100%;
              padding: 12px 14px;
              background: #f7f7f7;
              border: none;
              border-radius: 4px;
              font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
            .page .main .form_cont form table.form_tbl tr td input[type="text"].addnum,
            .single .main .form_cont form table.form_tbl tr td input[type="text"].addnum,
            .archive .main .form_cont form table.form_tbl tr td input[type="text"].addnum {
              width: 30%;
              margin-left: 10px; }
            .page .main .form_cont form table.form_tbl tr td #ddArea,
            .single .main .form_cont form table.form_tbl tr td #ddArea,
            .archive .main .form_cont form table.form_tbl tr td #ddArea {
              border: 2px #ccc dotted;
              text-align: center;
              background: #f7f7f7;
              padding: 30px;
              position: relative; }
              .page .main .form_cont form table.form_tbl tr td #ddArea p,
              .single .main .form_cont form table.form_tbl tr td #ddArea p,
              .archive .main .form_cont form table.form_tbl tr td #ddArea p {
                color: #888;
                padding-bottom: 0; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .dd_info,
              .single .main .form_cont form table.form_tbl tr td #ddArea .dd_info,
              .archive .main .form_cont form table.form_tbl tr td #ddArea .dd_info {
                font-size: 1.14em; }
              .page .main .form_cont form table.form_tbl tr td #ddArea label,
              .single .main .form_cont form table.form_tbl tr td #ddArea label,
              .archive .main .form_cont form table.form_tbl tr td #ddArea label {
                display: block;
                width: 50%;
                margin: 10px auto;
                color: #888;
                background-color: #fff;
                border-radius: 4px;
                border: 1px solid #ccc;
                padding: 8px 10px;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                position: relative;
                z-index: 2; }
                .page .main .form_cont form table.form_tbl tr td #ddArea label:hover,
                .single .main .form_cont form table.form_tbl tr td #ddArea label:hover,
                .archive .main .form_cont form table.form_tbl tr td #ddArea label:hover {
                  background-color: #f7f7f7;
                  border: 1px solid #ccc; }
                .page .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"],
                .single .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"],
                .archive .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"] {
                  display: none; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"],
              .single .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"],
              .archive .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"] {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                cursor: pointer; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .min,
              .single .main .form_cont form table.form_tbl tr td #ddArea .min,
              .archive .main .form_cont form table.form_tbl tr td #ddArea .min {
                padding-top: 10px;
                font-size: 0.85em; }
            .page .main .form_cont form table.form_tbl tr td textarea,
            .single .main .form_cont form table.form_tbl tr td textarea,
            .archive .main .form_cont form table.form_tbl tr td textarea {
              height: 160px; }
            .page .main .form_cont form table.form_tbl tr td .caution,
            .single .main .form_cont form table.form_tbl tr td .caution,
            .archive .main .form_cont form table.form_tbl tr td .caution {
              font-size: 0.92em;
              color: #888; }
        .page .main .form_cont form .cancel, .page .main .form_cont form .delete,
        .single .main .form_cont form .cancel,
        .single .main .form_cont form .delete,
        .archive .main .form_cont form .cancel,
        .archive .main .form_cont form .delete {
          display: block;
          text-align: right;
          cursor: pointer; }
        .page .main .form_cont button,
        .single .main .form_cont button,
        .archive .main .form_cont button {
          display: block;
          color: #033490;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #033490;
          padding: 15px;
          text-align: center;
          text-decoration: none;
          width: 70%;
          font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          cursor: pointer;
          margin: 20px auto 50px; }
          .page .main .form_cont button:hover,
          .single .main .form_cont button:hover,
          .archive .main .form_cont button:hover {
            color: #fff;
            background-color: #033490;
            transition: all .3s; }
  /*
 TB archive
---------------------------------------- */
  .archive .main {
    padding: 0; }
  .archive .cont_blog {
    width: 25%;
    background: url(../img/bg-wh.png);
    position: relative;
    float: left; }
    .archive .cont_blog:before {
      content: '';
      display: block;
      padding-top: 100%; }
    .archive .cont_blog_box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 7px;
      color: #1c6cc0; }
      .archive .cont_blog_box .blog_main {
        position: relative;
        z-index: 2; }
        .archive .cont_blog_box .blog_main .blog_tit {
          height: 3em;
          box-sizing: content-box;
          overflow: hidden;
          font-size: 1.28em;
          line-height: 1.6;
          padding: 5% 4% 2%;
          font-size: 0.92em; }
        .archive .cont_blog_box .blog_main .blog_date {
          font-family: "Lato", sans-serif;
          font-size: 0.71em;
          font-weight: 300;
          padding: 0 4%; }
      .archive .cont_blog_box .blog_img {
        width: 100%;
        height: 60%;
        overflow: hidden;
        text-align: center;
        margin: 0 auto; }
        .archive .cont_blog_box .blog_img img {
          width: 100%;
          height: auto;
          overflow: hidden;
          text-align: center;
          margin: 0 auto; }
      .archive .cont_blog_box .blog_nav {
        position: absolute;
        bottom: 8%;
        right: 8%; }
        .archive .cont_blog_box .blog_nav a {
          font-size: 0.71em;
          color: #1c6cc0; }
  .archive a .cont_blog {
    background: #f7f7f7; }
    .archive a .cont_blog:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive a:nth-child(3n) .cont_blog {
    background: #e6ebed; }
    .archive a:nth-child(3n) .cont_blog:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive a:nth-child(3n+1) .cont_blog {
    background: #fff; }
    .archive a:nth-child(3n+1) .cont_blog:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive .link_bnr {
    position: relative;
    overflow: hidden; }
    .archive .link_bnr:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-wh.png) no-repeat;
      background-size: contain;
      z-index: 99; }
    .archive .link_bnr:hover .hv-am {
      top: -25%;
      left: -25%; }
    .archive .link_bnr .hv-am {
      position: absolute;
      top: 100%;
      left: 100%;
      z-index: 2;
      width: 150%;
      height: 150%;
      transform: rotate(-45deg);
      background: rgba(28, 108, 192, 0.5);
      -webkit-transition: .6s;
      transition: .6s;
      opacity: 1; }
  .contest_result .main_inner .result_tit {
    padding-top: 20px; }
  .contest_result .main_inner .result_txt01 {
    font-size: 1.14em;
    font-weight: bolder;
    text-align: center;
    padding-bottom: 50px; }
  .contest_result .main_inner .result_txt02 {
    width: 620px;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    border: 1px #ddd solid;
    margin-bottom: 80px; }
  .contest_result .main_inner .result_txt03 {
    margin-bottom: 40px; }
  .contest_result .main_inner .result_img01 img {
    padding-bottom: 0; }
  .contest_result .main_inner .result_img02 img {
    padding-bottom: 20px; }
  .contest_result .main_inner .result_img03 {
    margin: 0 auto 50px;
    text-align: center; }
    .contest_result .main_inner .result_img03 a {
      display: inline-block; }
    .contest_result .main_inner .result_img03 img {
      padding-bottom: 5px;
      margin: 0 auto; }
    .contest_result .main_inner .result_img03 p {
      font-size: 0.85em;
      color: #666; } }

@media (max-width: 640px) {
  /*
 SP common
---------------------------------------- */
  .sp-on {
    display: block; }
  .pc-on {
    display: none; }
  body {
    font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 14px;
    line-height: 1.8;
    font-weight: 200;
    color: #666;
    position: relative;
    background: #3f4d69 url(../img/bg-bl.png); }
  /* =============== menu =============== */
  body.open .header_nav_btn img {
    -webkit-transition: all .5s;
    transition: all .5s;
    height: 24px; }
    body.open .header_nav_btn img.open {
      display: none; }
    body.open .header_nav_btn img.clone {
      display: block; }
  body.open .header_nav_menu {
    -webkit-transition: all .5s;
    transition: all .5s;
    visibility: visible;
    opacity: 1; }
  /* =============== header =============== */
  .header {
    width: 100%;
    position: absolute;
    z-index: 99999; }
    .header_nav_btn {
      height: 72px;
      position: fixed;
      padding: 26px 20px;
      display: inline-block;
      vertical-align: top;
      z-index: 15;
      cursor: pointer; }
      .header_nav_btn img.clone {
        display: none; }
      .header_nav_btn img {
        height: 22px; }
    .header_nav_menu {
      position: fixed;
      display: table;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(2, 50, 141, 0.9);
      -webkit-transition: all .5s;
      transition: all .5s;
      visibility: hidden;
      opacity: 0;
      z-index: 5; }
      .header_nav_menu ul {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding: 20px 0; }
        .header_nav_menu ul li {
          padding: 5px 0; }
          .header_nav_menu ul li a {
            text-decoration: none;
            font-size: 0.78em;
            color: #fff;
            line-height: 1.4;
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
            .header_nav_menu ul li a:hover {
              color: #1c6cc0; }
            .header_nav_menu ul li a span {
              display: block;
              font-family: "Lato", sans-serif;
              font-size: 1.78em;
              font-weight: 300;
              padding-bottom: 20px; }
            .header_nav_menu ul li a:hover {
              color: #888; }
    .header_logo {
      display: inline-block;
      padding: 26px 0 0 80px;
      vertical-align: top; }
      .header_logo img {
        height: 22px; }
    .header_tit {
      display: none; }
    .header_cv_btn {
      text-align: center;
      position: fixed;
      transition: .3s;
      top: 0;
      right: 0; }
      .header_cv_btn a {
        width: 72px;
        height: 72px;
        display: block;
        background: #02328d;
        background: -moz-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        background: -webkit-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        background: linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02328d', endColorstr='#0f64ff',GradientType=1 ); }
        .header_cv_btn a img {
          width: 36px;
          height: auto;
          text-align: center;
          margin: 0 auto;
          padding: 18px 0 20px; }
        .header_cv_btn a span {
          display: none; }
        .header_cv_btn a:hover {
          background: #0f64ff;
          background: -moz-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          background: -webkit-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          background: linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f64ff', endColorstr='#02328d',GradientType=1 ); }
  /* =============== footer =============== */
  .footer {
    border-top: 1px #ddd solid;
    padding: 0 4%;
    background: #fff; }
    .footer_cont .footer_nav {
      padding: 15px 0; }
      .footer_cont .footer_nav li {
        display: inline-block;
        font-size: 0.78em;
        letter-spacing: .05em; }
        .footer_cont .footer_nav li:after {
          content: ' ｜ '; }
        .footer_cont .footer_nav li:last-child:after {
          content: none; }
    .footer_cont .copyright {
      font-family: "Lato", sans-serif;
      font-size: 0.71em;
      font-weight: 500;
      letter-spacing: .05em; }
    .footer_bnr {
      padding: 10px 0 30px; }
      .footer_bnr img {
        width: auto;
        height: 40px; }
  /* ============ moving ============ */
  .move_cont, .page .main h2, .page .main img, .page .main h3, .page .main h4, .page .main ul, .page .main ol, .page .main p, .page .main div, .page .main table {
    opacity: 0;
    transform: translate(0, 60px);
    -webkit-transform: translate(0, 60px);
    transition: .8s; }
  .moving {
    opacity: 1.0 !important;
    transform: translate(0, 0) !important;
    -webkit-transform: translate(0, 0) !important; }
  .main form table p, .main form table div {
    opacity: inherit !important;
    transform: none !important;
    -webkit-transform: none !important;
    transition: inherit !important; }
  /*
 SP index
---------------------------------------- */
  .home {
    /* =============== hero =============== */
    /* =============== main =============== */ }
    .home .gray {
      display: none; }
    .home .hero {
      width: 100%;
      height: 65vh;
      background: #3f4d69 url(../img/hero_img.png) no-repeat;
      background-size: cover;
      color: #fff;
      position: relative; }
      .home .hero_cont {
        width: 100%;
        position: relative;
        z-index: 10; }
        .home .hero_cont .hero_txt {
          display: none; }
        .home .hero_cont .hero_copy {
          padding: 45vh 4% 0;
          font-size: 1.28em;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 300; }
      .home .hero .border01 {
        display: none; }
      .home .hero .border02 {
        width: 50%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        border-right: 1px rgba(255, 255, 255, 0.4) solid; }
      .home .hero .border03 {
        display: none; }
    .home .main {
      *zoom: 1; }
      .home .main:after {
        content: " ";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .home .main .area_left {
        width: 100%; }
        .home .main .area_left .cont_large {
          width: 75%;
          background: #033490;
          position: relative;
          float: left; }
          .home .main .area_left .cont_large:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_large_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; }
            .home .main .area_left .cont_large_box .tit {
              color: #fff;
              font-size: 30px;
              font-weight: 100;
              font-family: "Lato", sans-serif;
              line-height: 1em;
              padding: 25px 6% 10px;
              position: relative;
              z-index: 3; }
              .home .main .area_left .cont_large_box .tit span {
                font-weight: 900; }
            .home .main .area_left .cont_large_box .tit_sub {
              color: #fff;
              font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
              font-weight: 300;
              font-size: 0.92em;
              padding: 0 6%;
              position: relative;
              z-index: 3; }
            .home .main .area_left .cont_large_box img {
              width: 100%;
              height: auto;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 1;
              transform: translate(-50%, -50%); }
          .home .main .area_left .cont_large .border04 {
            width: 66.7%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            border-right: 1px rgba(255, 255, 255, 0.4) solid; }
          .home .main .area_left .cont_large .border05 {
            width: 100%;
            height: 66.7%;
            position: absolute;
            top: 0;
            z-index: 1;
            border-bottom: 1px rgba(255, 255, 255, 0.4) solid; }
        .home .main .area_left .cont_min-img_01 {
          width: 25%;
          float: left; }
          .home .main .area_left .cont_min-img_01 img {
            width: 100%;
            height: auto; }
        .home .main .area_left .cont_info {
          width: 50%;
          background: url(../img/bg-bl.png);
          position: relative;
          float: left; }
          .home .main .area_left .cont_info:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_info_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 8%;
            color: #fff; }
            .home .main .area_left .cont_info_box .tit {
              font-family: "Lato", sans-serif;
              font-weight: 300;
              font-size: 0.92em;
              line-height: 1em;
              padding-bottom: 2px; }
            .home .main .area_left .cont_info_box .tit_sub {
              font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
              font-weight: 300;
              font-size: 0.71em; }
              .home .main .area_left .cont_info_box .tit_sub:after {
                content: '';
                display: block;
                width: 40px;
                height: 1px;
                background: #fff;
                margin-top: 2px; }
            .home .main .area_left .cont_info_box .info_main {
              position: relative;
              padding-top: 10px; }
              .home .main .area_left .cont_info_box .info_main .info_tit {
                font-size: 0.92em;
                height: 3.2em;
                overflow: hidden; }
              .home .main .area_left .cont_info_box .info_main .info_date {
                font-family: "Lato", sans-serif;
                font-size: 0.71em;
                font-weight: 300;
                padding-top: 10px; }
            .home .main .area_left .cont_info_box .info_nav {
              position: absolute;
              bottom: 8%;
              right: 8%; }
              .home .main .area_left .cont_info_box .info_nav a {
                font-size: 0.71em;
                color: #fff; }
        .home .main .area_left .cont_img {
          width: 50%;
          background: #f4b318;
          background: -moz-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          background: -webkit-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          background: linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4b318', endColorstr='#e66c00',GradientType=1 );
          position: relative;
          float: left; }
          .home .main .area_left .cont_img:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_left .cont_img_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 80%;
            margin: auto;
            display: table; }
            .home .main .area_left .cont_img_box img {
              max-width: 100%;
              padding: 10px;
              margin: 0 auto;
              text-align: center;
              position: relative;
              -moz-transition: -moz-transform 0.3s linear;
              -webkit-transition: -webkit-transform 0.3s linear;
              -o-transition: -o-transform 0.3s linear;
              -ms-transition: -ms-transform 0.3s linear;
              transition: transform 0.3s linear; }
              .home .main .area_left .cont_img_box img:hover {
                -webkit-transform: scale(1.1, 1.1);
                -moz-transform: scale(1.1, 1.1);
                -o-transform: scale(1.1, 1.1);
                -ms-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1); }
      .home .main .cont_page {
        width: 50%;
        background: #333;
        position: relative;
        float: left; }
        .home .main .cont_page:before {
          content: '';
          display: block;
          padding-top: 100%; }
        .home .main .cont_page_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #fff; }
          .home .main .cont_page_box .tit {
            font-family: "Lato", sans-serif;
            font-weight: 100;
            font-size: 22px;
            line-height: 1em;
            padding: 20px 8% 5px;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_box .tit_sub {
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
            font-weight: 300;
            font-size: 0.78em;
            padding: 0 8%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_box img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%); }
      .home .main .cont_page_02 {
        width: 50%;
        background: #333;
        position: relative;
        float: left;
        background: #f7f7f7;
        border: 1px #fff solid; }
        .home .main .cont_page_02:before {
          content: '';
          display: block;
          padding-top: 100%; }
        .home .main .cont_page_02_box {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: #333; }
          .home .main .cont_page_02_box .tit {
            color: #ccc;
            font-family: "Lato", sans-serif;
            font-weight: 300;
            font-size: 22px;
            line-height: 1em;
            padding: 5% 8% 5%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_02_box .tit_sub {
            font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
            font-weight: 300;
            font-size: 0.78em;
            line-height: 1.4;
            padding: 0 8%;
            position: relative;
            z-index: 3; }
          .home .main .cont_page_02_box .img {
            width: 100%;
            height: 60%;
            overflow: hidden;
            text-align: center;
            margin: 0 auto; }
            .home .main .cont_page_02_box .img img {
              width: 100%;
              height: auto;
              text-align: center;
              margin: 0 auto; }
      .home .main .area_right {
        width: 100%; }
        .home .main .area_right .cont_blog {
          width: 50%;
          background: url(../img/bg-wh.png);
          position: relative;
          float: left; }
          .home .main .area_right .cont_blog:before {
            content: '';
            display: block;
            padding-top: 100%; }
          .home .main .area_right .cont_blog_box {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 7px;
            color: #1c6cc0; }
            .home .main .area_right .cont_blog_box .blog_main {
              position: relative;
              z-index: 2; }
              .home .main .area_right .cont_blog_box .blog_main .blog_tit {
                height: 2.7em;
                box-sizing: content-box;
                overflow: hidden;
                font-size: 0.85em;
                line-height: 1.4;
                padding: 4.5% 2% 2%; }
              .home .main .area_right .cont_blog_box .blog_main .blog_date {
                font-family: "Lato", sans-serif;
                font-size: 0.71em;
                font-weight: 300;
                padding: 0 2%; }
            .home .main .area_right .cont_blog_box .blog_img {
              width: 100%;
              height: 60%;
              overflow: hidden;
              text-align: center;
              margin: 0 auto; }
              .home .main .area_right .cont_blog_box .blog_img img {
                width: 100%;
                height: auto;
                overflow: hidden;
                text-align: center;
                margin: 0 auto; }
            .home .main .area_right .cont_blog_box .blog_nav {
              position: absolute;
              bottom: 8%;
              right: 8%; }
              .home .main .area_right .cont_blog_box .blog_nav a {
                font-size: 0.71em;
                color: #1c6cc0; }
        .home .main .area_right a:nth-child(1) .cont_blog,
        .home .main .area_right a:nth-child(4) .cont_blog,
        .home .main .area_right a:nth-child(5) .cont_blog {
          background: #f7f7f7; }
          .home .main .area_right a:nth-child(1) .cont_blog:after,
          .home .main .area_right a:nth-child(4) .cont_blog:after,
          .home .main .area_right a:nth-child(5) .cont_blog:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url(../img/arw-wh.png) no-repeat;
            background-size: contain;
            z-index: 99; }
        .home .main .area_right a:nth-child(2) .cont_blog,
        .home .main .area_right a:nth-child(3) .cont_blog {
          background: #fff; }
          .home .main .area_right a:nth-child(2) .cont_blog:after,
          .home .main .area_right a:nth-child(3) .cont_blog:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: 0;
            background: url(../img/arw-gr.png) no-repeat;
            background-size: contain;
            z-index: 99; }
        .home .main .area_right .cont_min-img_02 {
          width: 100%; }
          .home .main .area_right .cont_min-img_02 img {
            float: left;
            width: 25%;
            height: auto; }
      .home .main .link_bnr {
        position: relative;
        overflow: hidden; }
        .home .main .link_bnr:after {
          content: '';
          display: block;
          width: 40px;
          height: 40px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(../img/arw-wh.png) no-repeat;
          background-size: contain;
          z-index: 99; }
        .home .main .link_bnr:hover .hv-am {
          top: -25%;
          left: -25%; }
        .home .main .link_bnr .hv-am {
          position: absolute;
          top: 100%;
          left: 100%;
          z-index: 2;
          width: 150%;
          height: 150%;
          transform: rotate(-45deg);
          background: rgba(28, 108, 192, 0.5);
          -webkit-transition: .6s;
          transition: .6s;
          opacity: 1; }
  /*
 SP page
---------------------------------------- */
  .page,
  .single,
  .archive {
    /* =============== hero =============== */
    /* =============== main =============== */ }
    .page .white,
    .single .white,
    .archive .white {
      display: none; }
    .page .header_tit,
    .single .header_tit,
    .archive .header_tit {
      color: #888; }
    .page .hero,
    .single .hero,
    .archive .hero {
      width: 100%;
      height: 34vh;
      background: #3f4d69 url(../img/page-top.png) repeat-x center;
      background-size: auto 100%;
      color: #fff;
      position: relative; }
      .page .hero_cont,
      .single .hero_cont,
      .archive .hero_cont {
        width: 100%;
        position: absolute;
        z-index: 10;
        bottom: 10px;
        padding-left: 40px; }
        .page .hero_cont .breadcrumb,
        .single .hero_cont .breadcrumb,
        .archive .hero_cont .breadcrumb {
          display: none; }
    .page.contest .hero,
    .single.contest .hero,
    .archive.contest .hero {
      background: #3f4d69 url(../img/contest_hero.png) repeat-x center;
      background-size: auto 100%; }
    .page.contest_result .hero,
    .single.contest_result .hero,
    .archive.contest_result .hero {
      background: #3f4d69 url(../img/result_bg.png) repeat-x center;
      background-size: auto 100%; }
    .page .main,
    .single .main,
    .archive .main {
      *zoom: 1;
      background-image: url(../img/bg-wh.png);
      padding: 0 6%;
      /* =============== form =============== */ }
      .page .main:after,
      .single .main:after,
      .archive .main:after {
        content: " ";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      .page .main_inner,
      .single .main_inner,
      .archive .main_inner {
        max-width: 720px;
        min-height: 600px;
        margin: 0 auto;
        padding: 20px 0 10px; }
        .page .main_inner h2,
        .single .main_inner h2,
        .archive .main_inner h2 {
          margin: 0 0 20px;
          position: relative;
          padding: 0 10px;
          font-size: 1.07em;
          color: #033490;
          padding: 30px 4% 34px;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          border-right: 1px solid #033490;
          border-left: 1px solid #033490;
          line-height: 1; }
          .page .main_inner h2:before, .page .main_inner h2:after,
          .single .main_inner h2:before,
          .single .main_inner h2:after,
          .archive .main_inner h2:before,
          .archive .main_inner h2:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 30px; }
          .page .main_inner h2:before,
          .single .main_inner h2:before,
          .archive .main_inner h2:before {
            left: -5px;
            border-top: solid 1px #033490;
            top: 5px; }
          .page .main_inner h2:after,
          .single .main_inner h2:after,
          .archive .main_inner h2:after {
            right: -5px;
            border-bottom: solid 1px #033490;
            bottom: 5px; }
        .page .main_inner img,
        .single .main_inner img,
        .archive .main_inner img {
          max-width: 100%;
          height: auto;
          padding-bottom: 20px; }
        .page .main_inner p,
        .single .main_inner p,
        .archive .main_inner p {
          font-size: 12px;
          padding-bottom: 20px; }
          .page .main_inner p.medium,
          .single .main_inner p.medium,
          .archive .main_inner p.medium {
            font-size: 1.07em; }
          .page .main_inner p.large,
          .single .main_inner p.large,
          .archive .main_inner p.large {
            font-size: 1.14em; }
        .page .main_inner h3,
        .single .main_inner h3,
        .archive .main_inner h3 {
          font-size: 1.07em;
          color: #033490;
          padding: 15px 0;
          border-top: 1px solid #033490;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          margin-bottom: 20px;
          position: relative; }
          .page .main_inner h3:after,
          .single .main_inner h3:after,
          .archive .main_inner h3:after {
            content: "";
            position: absolute;
            width: 60%;
            bottom: 0;
            left: 0;
            border-top: 1px #033490 solid;
            height: 1px; }
        .page .main_inner h4,
        .single .main_inner h4,
        .archive .main_inner h4 {
          padding: 10px 4%;
          background-color: #e6ebed;
          font-family: "Yu Gothic", YuGothic, "游ゴシック", "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-weight: 500;
          margin-bottom: 16px;
          border-radius: 3px; }
        .page .main_inner table,
        .single .main_inner table,
        .archive .main_inner table {
          width: 100%;
          margin-bottom: 20px;
          font-size: 0.85em; }
          .page .main_inner table th,
          .page .main_inner table td,
          .single .main_inner table th,
          .single .main_inner table td,
          .archive .main_inner table th,
          .archive .main_inner table td {
            padding: 10px 0;
            text-align: center; }
          .page .main_inner table tr:first-child,
          .single .main_inner table tr:first-child,
          .archive .main_inner table tr:first-child {
            color: #fff; }
            .page .main_inner table tr:first-child th:nth-child(odd),
            .single .main_inner table tr:first-child th:nth-child(odd),
            .archive .main_inner table tr:first-child th:nth-child(odd) {
              background-color: #3f4d69; }
            .page .main_inner table tr:first-child th:nth-child(even),
            .single .main_inner table tr:first-child th:nth-child(even),
            .archive .main_inner table tr:first-child th:nth-child(even) {
              background-color: #406bb2; }
          .page .main_inner table tr:nth-child(odd) td:nth-child(odd),
          .single .main_inner table tr:nth-child(odd) td:nth-child(odd),
          .archive .main_inner table tr:nth-child(odd) td:nth-child(odd) {
            background-color: #e6ebed; }
          .page .main_inner table tr:nth-child(odd) td:nth-child(even),
          .single .main_inner table tr:nth-child(odd) td:nth-child(even),
          .archive .main_inner table tr:nth-child(odd) td:nth-child(even) {
            background-color: #f7f7f7; }
          .page .main_inner table tr:nth-child(even) td:nth-child(odd),
          .single .main_inner table tr:nth-child(even) td:nth-child(odd),
          .archive .main_inner table tr:nth-child(even) td:nth-child(odd) {
            background-color: #f7f7f7; }
          .page .main_inner table tr:nth-child(even) td:nth-child(even),
          .single .main_inner table tr:nth-child(even) td:nth-child(even),
          .archive .main_inner table tr:nth-child(even) td:nth-child(even) {
            background-color: #fff; }
        .page .main_inner ul,
        .single .main_inner ul,
        .archive .main_inner ul {
          font-size: 0.85em;
          margin-bottom: 20px; }
          .page .main_inner ul li,
          .single .main_inner ul li,
          .archive .main_inner ul li {
            list-style-image: url(../img/ico_list.png);
            margin-left: 20px;
            margin-bottom: 10px; }
        .page .main_inner ol,
        .single .main_inner ol,
        .archive .main_inner ol {
          margin-bottom: 30px; }
          .page .main_inner ol li,
          .single .main_inner ol li,
          .archive .main_inner ol li {
            list-style: decimal;
            list-style-position: inside;
            margin-bottom: 10px;
            font-size: 0.78em; }
        .page .main_inner .btn_link,
        .single .main_inner .btn_link,
        .archive .main_inner .btn_link {
          margin: 20px 0; }
          .page .main_inner .btn_link a,
          .single .main_inner .btn_link a,
          .archive .main_inner .btn_link a {
            display: block;
            width: 100%;
            color: #033490;
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid #033490;
            padding: 10px 4%;
            text-align: center;
            text-decoration: none; }
            .page .main_inner .btn_link a:hover,
            .single .main_inner .btn_link a:hover,
            .archive .main_inner .btn_link a:hover {
              color: #fff;
              background-color: #033490;
              transition: all .3s; }
        .page .main_inner em,
        .single .main_inner em,
        .archive .main_inner em {
          font-style: oblique; }
        .page .main_inner strong, .page .main_inner b,
        .single .main_inner strong,
        .single .main_inner b,
        .archive .main_inner strong,
        .archive .main_inner b {
          font-weight: bold; }
      .page .main .pager_inner,
      .single .main .pager_inner,
      .archive .main .pager_inner {
        margin: 0 auto;
        padding: 0 0 70px; }
        .page .main .pager_inner .pager_cont,
        .single .main .pager_inner .pager_cont,
        .archive .main .pager_inner .pager_cont {
          *zoom: 1;
          padding: 10px 0;
          border-top: 1px #888 solid;
          border-bottom: 1px #888 solid; }
          .page .main .pager_inner .pager_cont:after,
          .single .main .pager_inner .pager_cont:after,
          .archive .main .pager_inner .pager_cont:after {
            content: " ";
            display: block;
            clear: both;
            height: 0;
            visibility: hidden; }
        .page .main .pager_inner a,
        .single .main .pager_inner a,
        .archive .main .pager_inner a {
          font-size: 0; }
          .page .main .pager_inner a.prev,
          .single .main .pager_inner a.prev,
          .archive .main .pager_inner a.prev {
            width: 50%;
            float: left;
            border-right: 1px #888 dotted; }
          .page .main .pager_inner a.next,
          .single .main .pager_inner a.next,
          .archive .main .pager_inner a.next {
            text-align: right;
            width: 50%;
            float: right; }
          .page .main .pager_inner a img,
          .single .main .pager_inner a img,
          .archive .main .pager_inner a img {
            width: 50px;
            height: auto;
            display: inline-block;
            vertical-align: middle; }
          .page .main .pager_inner a p,
          .single .main .pager_inner a p,
          .archive .main .pager_inner a p {
            width: calc(98% - 50px);
            line-height: 1.4;
            padding: 0 4%;
            font-size: 10px;
            display: inline-block;
            vertical-align: middle;
            text-align: left; }
      .page .main .form_cont,
      .single .main .form_cont,
      .archive .main .form_cont {
        padding-top: 20px; }
        .page .main .form_cont .req,
        .single .main .form_cont .req,
        .archive .main .form_cont .req {
          display: inline-block;
          vertical-align: top;
          font-size: 0.71em;
          background: #1c6cc0;
          color: #fff;
          padding: 0 5px 1px;
          margin: 3px 5px 3px 0; }
        .page .main .form_cont form table.form_tbl,
        .single .main .form_cont form table.form_tbl,
        .archive .main .form_cont form table.form_tbl {
          width: 100%;
          border-top: 1px #e6ebed solid;
          margin-bottom: 10px; }
          .page .main .form_cont form table.form_tbl tr th,
          .page .main .form_cont form table.form_tbl tr td,
          .single .main .form_cont form table.form_tbl tr th,
          .single .main .form_cont form table.form_tbl tr td,
          .archive .main .form_cont form table.form_tbl tr th,
          .archive .main .form_cont form table.form_tbl tr td {
            display: block;
            width: 100%;
            color: #666;
            text-align: left;
            background: #fff !important; }
          .page .main .form_cont form table.form_tbl tr th,
          .single .main .form_cont form table.form_tbl tr th,
          .archive .main .form_cont form table.form_tbl tr th {
            padding: 14px 4% 8px; }
          .page .main .form_cont form table.form_tbl tr td,
          .single .main .form_cont form table.form_tbl tr td,
          .archive .main .form_cont form table.form_tbl tr td {
            padding: 0 4% 14px;
            border-bottom: 1px #e6ebed solid; }
            .page .main .form_cont form table.form_tbl tr td input[type="text"],
            .page .main .form_cont form table.form_tbl tr td input[type="email"],
            .page .main .form_cont form table.form_tbl tr td textarea,
            .single .main .form_cont form table.form_tbl tr td input[type="text"],
            .single .main .form_cont form table.form_tbl tr td input[type="email"],
            .single .main .form_cont form table.form_tbl tr td textarea,
            .archive .main .form_cont form table.form_tbl tr td input[type="text"],
            .archive .main .form_cont form table.form_tbl tr td input[type="email"],
            .archive .main .form_cont form table.form_tbl tr td textarea {
              width: 100%;
              padding: 10px 4%;
              background: #f7f7f7;
              border: none;
              border-radius: 4px;
              font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
            .page .main .form_cont form table.form_tbl tr td input[type="text"].addnum,
            .single .main .form_cont form table.form_tbl tr td input[type="text"].addnum,
            .archive .main .form_cont form table.form_tbl tr td input[type="text"].addnum {
              width: 70%;
              margin-left: 10px; }
            .page .main .form_cont form table.form_tbl tr td #ddArea,
            .single .main .form_cont form table.form_tbl tr td #ddArea,
            .archive .main .form_cont form table.form_tbl tr td #ddArea {
              border: 2px #ccc dotted;
              text-align: center;
              background: #f7f7f7;
              padding: 30px;
              position: relative; }
              .page .main .form_cont form table.form_tbl tr td #ddArea p,
              .single .main .form_cont form table.form_tbl tr td #ddArea p,
              .archive .main .form_cont form table.form_tbl tr td #ddArea p {
                color: #888;
                padding-bottom: 0; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .dd_info,
              .single .main .form_cont form table.form_tbl tr td #ddArea .dd_info,
              .archive .main .form_cont form table.form_tbl tr td #ddArea .dd_info {
                font-size: 1.14em; }
              .page .main .form_cont form table.form_tbl tr td #ddArea label,
              .single .main .form_cont form table.form_tbl tr td #ddArea label,
              .archive .main .form_cont form table.form_tbl tr td #ddArea label {
                display: block;
                width: 70%;
                margin: 10px auto;
                color: #888;
                background-color: #fff;
                border-radius: 4px;
                border: 1px solid #ccc;
                padding: 8px 10px;
                text-align: center;
                text-decoration: none;
                cursor: pointer;
                position: relative;
                z-index: 2; }
                .page .main .form_cont form table.form_tbl tr td #ddArea label:hover,
                .single .main .form_cont form table.form_tbl tr td #ddArea label:hover,
                .archive .main .form_cont form table.form_tbl tr td #ddArea label:hover {
                  background-color: #f7f7f7;
                  border: 1px solid #ccc; }
                .page .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"],
                .single .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"],
                .archive .main .form_cont form table.form_tbl tr td #ddArea label input[type="file"] {
                  display: none; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"],
              .single .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"],
              .archive .main .form_cont form table.form_tbl tr td #ddArea .dd_btn input[type="file"] {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                cursor: pointer; }
              .page .main .form_cont form table.form_tbl tr td #ddArea .min,
              .single .main .form_cont form table.form_tbl tr td #ddArea .min,
              .archive .main .form_cont form table.form_tbl tr td #ddArea .min {
                padding-top: 10px;
                font-size: 0.85em; }
            .page .main .form_cont form table.form_tbl tr td textarea,
            .single .main .form_cont form table.form_tbl tr td textarea,
            .archive .main .form_cont form table.form_tbl tr td textarea {
              height: 160px; }
            .page .main .form_cont form table.form_tbl tr td .caution,
            .single .main .form_cont form table.form_tbl tr td .caution,
            .archive .main .form_cont form table.form_tbl tr td .caution {
              font-size: 0.92em;
              color: #888; }
        .page .main .form_cont form .files .name,
        .single .main .form_cont form .files .name,
        .archive .main .form_cont form .files .name {
          width: 100%;
          margin-top: 14px; }
        .page .main .form_cont form .cancel, .page .main .form_cont form .delete,
        .single .main .form_cont form .cancel,
        .single .main .form_cont form .delete,
        .archive .main .form_cont form .cancel,
        .archive .main .form_cont form .delete {
          display: block;
          margin-top: 14px;
          cursor: pointer; }
        .page .main .form_cont button,
        .single .main .form_cont button,
        .archive .main .form_cont button {
          display: block;
          width: 100%;
          color: #033490;
          background-color: #fff;
          border-radius: 4px;
          border: 1px solid #033490;
          padding: 10px 4%;
          text-align: center;
          text-decoration: none;
          font-family: "Noto Sans Japanese", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          cursor: pointer;
          margin: 10px auto 20px; }
          .page .main .form_cont button:hover,
          .single .main .form_cont button:hover,
          .archive .main .form_cont button:hover {
            color: #fff;
            background-color: #033490;
            transition: all .3s; }
  /*
 SP archive
---------------------------------------- */
  .archive .main {
    padding: 0; }
  .archive .cont_blog {
    width: 50%;
    background: url(../img/bg-wh.png);
    position: relative;
    float: left; }
    .archive .cont_blog:before {
      content: '';
      display: block;
      padding-top: 100%; }
    .archive .cont_blog_box {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 7px;
      color: #1c6cc0; }
      .archive .cont_blog_box .blog_main {
        position: relative;
        z-index: 2; }
        .archive .cont_blog_box .blog_main .blog_tit {
          height: 2.7em;
          box-sizing: content-box;
          overflow: hidden;
          font-size: 0.85em;
          line-height: 1.4;
          padding: 4.5% 2% 2%; }
        .archive .cont_blog_box .blog_main .blog_date {
          font-family: "Lato", sans-serif;
          font-size: 0.71em;
          font-weight: 300;
          padding: 0 2%; }
      .archive .cont_blog_box .blog_img {
        width: 100%;
        height: 60%;
        overflow: hidden;
        text-align: center;
        margin: 0 auto; }
        .archive .cont_blog_box .blog_img img {
          width: 100%;
          height: auto;
          overflow: hidden;
          text-align: center;
          margin: 0 auto; }
      .archive .cont_blog_box .blog_nav {
        position: absolute;
        bottom: 8%;
        right: 8%; }
        .archive .cont_blog_box .blog_nav a {
          font-size: 0.71em;
          color: #1c6cc0; }
  .archive a .cont_blog {
    background: #f7f7f7; }
    .archive a .cont_blog:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive a:nth-child(3n) .cont_blog {
    background: #e6ebed; }
    .archive a:nth-child(3n) .cont_blog:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive a:nth-child(3n+1) .cont_blog {
    background: #fff; }
    .archive a:nth-child(3n+1) .cont_blog:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-gr.png) no-repeat;
      background-size: contain;
      z-index: 99; }
  .archive .link_bnr {
    position: relative;
    overflow: hidden; }
    .archive .link_bnr:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/arw-wh.png) no-repeat;
      background-size: contain;
      z-index: 99; }
    .archive .link_bnr:hover .hv-am {
      top: -25%;
      left: -25%; }
    .archive .link_bnr .hv-am {
      position: absolute;
      top: 100%;
      left: 100%;
      z-index: 2;
      width: 150%;
      height: 150%;
      transform: rotate(-45deg);
      background: rgba(28, 108, 192, 0.5);
      -webkit-transition: .6s;
      transition: .6s;
      opacity: 1; }
  .contest_result .main_inner .result_tit {
    padding-top: 10px; }
  .contest_result .main_inner .result_txt01 {
    font-weight: bolder;
    text-align: center;
    padding-bottom: 25px; }
  .contest_result .main_inner .result_txt02 {
    width: 100%;
    margin: 0 auto;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    border: 1px #ddd solid;
    margin-bottom: 40px;
    font-size: 0.78em; }
  .contest_result .main_inner .result_txt03 {
    margin-bottom: 20px; }
  .contest_result .main_inner .result_img01 img {
    padding-bottom: 0; }
  .contest_result .main_inner .result_img02 img {
    padding-bottom: 10px; }
  .contest_result .main_inner .result_img03 {
    width: 40%;
    margin: 0 auto 25px;
    text-align: center; }
    .contest_result .main_inner .result_img03 a {
      display: inline-block; }
    .contest_result .main_inner .result_img03 img {
      width: 60%;
      padding-bottom: 5px;
      margin: 0 auto; }
    .contest_result .main_inner .result_img03 p {
      font-size: 0.71em;
      color: #666; } }

/* mw wp form custom */
.page .main .main_inner .form_cont .btn_back {
  margin-bottom: 10px !important;
  color: #888 !important;
  border: 1px #888 solid !important; }

.page .main .main_inner .form_cont .btn_back:hover {
  background-color: #888 !important;
  color: #fff !important; }

.page .main .main_inner .form_cont .mwform-file-delete {
  display: none; }

.page .main .main_inner .form_cont .filesname {
  display: flex;
  word-break: break-all;
  position: relative;
  z-index: 9999;
  justify-content: space-between;
  align-items: center;
  text-align: left; }

.page .main .main_inner .form_cont .mw-wp-form_file {
  text-align: left;
  position: relative;
  z-index: 9999; }

.page .main .main_inner .form_cont .filesname_delete {
  padding: 0;
  width: 6em;
  color: #888;
  cursor: pointer;
  text-align: right; }

.page .main .main_inner .form_cont .mw-wp-form_image img {
  padding-bottom: 0; }

.page .main .main_inner .form_cont form p {
  padding: 0; }

.page .main .main_inner .form_cont form .filesbtn {
  cursor: pointer;
  color: #1c6cc0; }
  .page .main .main_inner .form_cont form .filesbtn:hover {
    text-decoration: underline; }

.page .main .main_inner .form_cont .mw_wp_form_confirm .caution {
  display: none; }

.page .main .main_inner .form_cont .mw_wp_form_confirm form table.form_tbl tr td #ddArea {
  border: none;
  text-align: left;
  background: #fff;
  padding: 0; }
  .page .main .main_inner .form_cont .mw_wp_form_confirm form table.form_tbl tr td #ddArea p {
    display: none; }

.page .main .main_inner .form_cont .mw_wp_form_confirm .filesname {
  display: none; }
