// ----- fonts

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

$font-family: 'Noto Sans Japanese', Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Yu Gothic", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
$font-family-yugo: "Yu Gothic", YuGothic, "游ゴシック", 'Noto Sans Japanese', Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
$font-family-lato: 'Lato', sans-serif;

$font-size: 14px;
$line-height: 1.8;

$fs-10: .71em;
$fs-11: .78em;
$fs-12: .85em;
$fs-13: .92em;
$fs-15: 1.07em;
$fs-16: 1.14em;
$fs-17: 1.21em;
$fs-18: 1.28em;
$fs-19: 1.35em;
$fs-20: 1.42em;
$fs-21: 1.5em;
$fs-22: 1.57em;
$fs-23: 1.64em;
$fs-24: 1.71em;
$fs-25: 1.78em;
$fs-28: 2em;
$fs-32: 2.28em;
$fs-48: 3.42em;

// ----- colors

$color: #666;

$cl-baseBlue: #033490;
$cl-lightBlue: #1c6cc0;
$cl-lightBlueDark: #406bb2;
$cl-darkBlue: #3f4d69;

$cl-gray: #888;
$cl-lightGray: #e6ebed;
$cl-lightGrayLight: #f7f7f7;

// ----- layout