/*
 SP common
---------------------------------------- */

.sp-on {
  display: block;
}

.pc-on {
  display: none;
}

body {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 200;
  color: $color;
  position: relative;
  background: $cl-darkBlue url(../img/bg-bl.png);
}


/* =============== menu =============== */

body.open {
  .header_nav_btn {
    img {
      -webkit-transition: all .5s;
      transition: all .5s;
      height: 24px;
      &.open {
        display: none;
      }
      &.clone {
        display: block;
      }
    }
  }
  .header_nav_menu {
    -webkit-transition: all .5s;
    transition: all .5s;
    visibility: visible;
    opacity: 1;
  }
}


/* =============== header =============== */

.header {
  width: 100%;
  position: absolute;
  z-index: 99999;
  &_nav_btn {
    height: 72px;
    position: fixed;
    padding: 26px 20px;
    display: inline-block;
    vertical-align: top;
    z-index: 15;
    cursor: pointer;
    img.clone {
      display: none;
    }
    img {
      height: 22px;
    }
  }
  &_nav_menu {
    position: fixed;
    display: table;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(2,50,141,.9);
    -webkit-transition: all .5s;
    transition: all .5s;
    visibility: hidden;
    opacity: 0;
    z-index: 5;
    ul {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      padding: 20px 0;
      li {
        padding: 5px 0;
        a {
          text-decoration: none;
          font-size: $fs-11;
          color: #fff;
          line-height: 1.4;
          font-family: $font-family-yugo;
          &:hover {
            color: #1c6cc0;
          }
          span {
            display: block;
            font-family: $font-family-lato;
            font-size: $fs-25;
            font-weight: 300;
            padding-bottom: 20px;
          }
          &:hover {
            color: $cl-gray;
          }
        }
      }
    }
  }
  &_logo {
    display: inline-block;
    padding: 26px 0 0 80px;
    vertical-align: top;
    img {
      height: 22px;
    }
  }
  &_tit {
    display: none;
  }
  &_cv_btn {
    text-align: center;
    position: fixed;
    transition: .3s;
    top: 0;
    right: 0;
    a {
      width: 72px;
      height: 72px;
      display: block;
      @include gr-blue;
      img {
        width: 36px;
        height: auto;
        text-align: center;
        margin: 0 auto;
        padding: 18px 0 20px;
      }
      span {
        display: none;
      }
      &:hover {
        @include gr-blue-hover;
      }
    }
  }
}


/* =============== footer =============== */

.footer {
  border-top: 1px #ddd solid;
  padding: 0 4%;
  background: #fff;
  &_cont {
    .footer_nav {
      padding: 15px 0;
      li {
        display: inline-block;
        font-size: $fs-11;
        letter-spacing: .05em;
        &:after {
          content: ' ｜ ';
        }
        &:last-child:after {
          content: none;
        }
      }
    }
    .copyright {
      font-family: $font-family-lato;
      font-size: $fs-10;
      font-weight: 500;
      letter-spacing: .05em;
    }
  }
  &_bnr {
    padding: 10px 0 30px;
    img {
      width: auto;
      height: 40px;
    }
  }
}

/* ============ moving ============ */

.move_cont, .page .main h2, .page .main img, .page .main h3, .page .main h4, .page .main ul, .page .main ol, .page .main p, .page .main div, .page .main table {
  opacity: 0;
  transform: translate(0, 60px);
  -webkit-transform: translate(0, 60px);
  transition: .8s;
}
.moving {
  opacity: 1.0 !important;
  transform: translate(0, 0) !important;
  -webkit-transform: translate(0, 0) !important;
}
.main form table p, .main form table div {
  opacity: inherit !important;
  transform: none !important;
  -webkit-transform: none !important;
  transition: inherit !important;
}