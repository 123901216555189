@charset "UTF-8";

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(//fonts.googleapis.com/css?family=Lato:100,300,400,700,900);

@import "component/config";
@import "component/mixin";

@import "component/reset";
@import "component/base";

@media (min-width: 1025px) {
	@import "parts/pc/pc-common";
  @import "parts/pc/pc-index";
  @import "parts/pc/pc-page";
}

@media (min-width: 641px) and (max-width: 1024px) {
	@import "parts/tb/tb-common";
  @import "parts/tb/tb-index";
  @import "parts/tb/tb-page";
}

@media (max-width: 640px) {
	@import "parts/sp/sp-common";
  @import "parts/sp/sp-index";
   @import "parts/sp/sp-page";
}

/* mw wp form custom */

.page .main .main_inner {
  .form_cont {
    .btn_back {
      margin-bottom: 10px !important;
      color: #888 !important;
      border: 1px #888 solid !important;
    }
    .btn_back:hover {
      background-color: #888 !important;
      color: #fff !important;
    }
    .mwform-file-delete {
      display: none;
    }
    .filesname {
      display: flex;
      word-break: break-all;
      position: relative;
      z-index: 9999;
      justify-content: space-between;
      align-items: center;
      text-align: left;
    }
    .mw-wp-form_file {
      text-align: left;
      position: relative;
      z-index: 9999;
    }
    .filesname_delete {
      padding: 0;
      width: 6em;
      color: #888;
      cursor: pointer;
      text-align: right;
    }
    .mw-wp-form_image img {
      padding-bottom: 0;
    }
    form {
      p {
        padding: 0;
      }
      .filesbtn {
        cursor: pointer;
        color: $cl-lightBlue;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .mw_wp_form_confirm {
      .caution {
        display: none;
      }
      form table.form_tbl tr td #ddArea {
        border: none;
        text-align: left;
        background: #fff;
        padding: 0;
        p {
          display: none;
        }
      }
      .filesname {
        display: none;
      }
    }
  }
}