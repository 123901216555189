//  -------------------- mixin
//　-----　clearfix
@mixin clearfix {
	*zoom: 1;
	&:after {
		content: " ";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
	}
}

@mixin gr-blue {
  background: #02328d;
  background: -moz-linear-gradient(45deg, #02328d 0%, #0f64ff 100%);
  background: -webkit-linear-gradient(45deg, #02328d 0%,#0f64ff 100%);
  background: linear-gradient(45deg, #02328d 0%,#0f64ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02328d', endColorstr='#0f64ff',GradientType=1 );
}
@mixin gr-blue-hover {
  background: #0f64ff;
  background: -moz-linear-gradient(45deg, #0f64ff 0%, #02328d 100%);
  background: -webkit-linear-gradient(45deg, #0f64ff 0%,#02328d 100%);
  background: linear-gradient(45deg, #0f64ff 0%,#02328d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f64ff', endColorstr='#02328d',GradientType=1 );
}
@mixin gr-orange {
  background: #f4b318;
  background: -moz-linear-gradient(45deg, #f4b318 0%, #e66c00 100%);
  background: -webkit-linear-gradient(45deg, #f4b318 0%,#e66c00 100%);
  background: linear-gradient(45deg, #f4b318 0%,#e66c00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4b318', endColorstr='#e66c00',GradientType=1 );
}

@mixin heightMatch {
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
@mixin heightMatchCont {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
