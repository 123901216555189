/*
 PC index
---------------------------------------- */

.home {
  .gray {
    display: none;
  }
  
  /* =============== hero =============== */
  
  .hero {
    width: 100%;
    height: 95vh;
    max-height: 680px;
    background: $cl-darkBlue url(../img/hero_img.png) repeat-x center;
    color: #fff;
    position: relative;
    &_cont {
      width: 100%;
      position: absolute;
      z-index: 10;
      bottom: 100px;
      padding-left: 3%;
      .hero_txt {
        padding-bottom: 30px;
        width: 50%;
      }
      .hero_copy {
        font-size: 30px;
        font-family: $font-family-yugo;
        font-weight: 300;
      }
    }
    .border01 {
      width: 25%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      border-right: 1px rgba(255, 255, 255, .4) solid;
    }
    .border02 {
      width: 50%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      border-right: 1px rgba(255, 255, 255, .4) solid;
    }
    .border03 {
      width: 75%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      border-right: 1px rgba(255, 255, 255, .4) solid;
    }
  }
  /* =============== main =============== */
  .main {
    @include clearfix;
    padding: 3%;
    .area_left {
      width: 50%;
      float: left;
      .cont_large {
        width: 75%;
        background: $cl-baseBlue;
        position: relative;
        float: left;
        @include heightMatch;
        &_box {
          @include heightMatchCont;
          .tit {
            color: #fff;
            font-size: 70px;
            font-weight: 100;
            font-family: $font-family-lato;
            line-height: 1em;
            padding: 50px 30px 20px;
            position: relative;
            z-index: 3;
            span {
              font-weight: 900;
            }
          }
          .tit_sub {
            color: #fff;
            font-family: $font-family-yugo;
            font-weight: 300;
            font-size: $fs-15;
            padding: 0 30px;
            position: relative;
            z-index: 3;
          }
          img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
          }
        }
        .border04 {
          width: 66.7%;
          height: 100%;
          position: absolute;
          top: 0;
          z-index: 1;
          border-right: 1px rgba(255, 255, 255, .4) solid;
        }
        .border05 {
          width: 100%;
          height: 66.7%;
          position: absolute;
          top: 0;
          z-index: 1;
          border-bottom: 1px rgba(255, 255, 255, .4) solid;
        }
      }
      .cont_min-img_01 {
        width: 25%;
        float: left;
        img {
          width: 100%;
          height: auto;
        }
      }
      .cont_info {
        width: 50%;
        background: url(../img/bg-bl.png);
        position: relative;
        float: left;
        @include heightMatch;
        &_box {
          @include heightMatchCont;
          padding: 10%;
          color: #fff;
          .tit {
            font-family: $font-family-lato;
            font-weight: 300;
            font-size: 22px;
          }
          .tit_sub {
            font-family: $font-family-yugo;
            font-weight: 300;
            font-size: $fs-13;
            &:after {
              content: '';
              display: block;
              width: 60px;
              height: 2px;
              background: #fff;
              margin-top: 10px;
            }
          }
          .info_main {
            position: relative;
            padding-top: 10%;
            .info_tit {
              font-size: $fs-20;
              height: 3.2em;
              overflow: hidden;
            }
            .info_date {
              font-family: $font-family-lato;
              font-size: $fs-13;
              font-weight: 300;
              padding-top: 10%;
            }
          }
          .info_nav {
            position: absolute;
            bottom: 10%;
            right: 10%;
            a {
              color: #fff;
            }
          }
        }
      }
      .cont_img {
        width: 50%;
        overflow: hidden;
        @include gr-orange;
        position: relative;
        float: left;
        @include heightMatch;
        &_box {
          @include heightMatchCont;
          width: 100%;
          height: 80%;
          margin: auto;
          display: table;
          img {
            max-width: 100%;
            padding: 10px;
            margin: 0 auto;
            text-align: center;
            position: relative;
            -moz-transition: -moz-transform 0.3s linear;
            -webkit-transition: -webkit-transform 0.3s linear;
            -o-transition: -o-transform 0.3s linear;
            -ms-transition: -ms-transform 0.3s linear;
            transition: transform 0.3s linear;
            &:hover {
              -webkit-transform: scale(1.1, 1.1);
              -moz-transform: scale(1.1, 1.1);
              -o-transform: scale(1.1, 1.1);
              -ms-transform: scale(1.1, 1.1);
              transform: scale(1.1, 1.1);
            }
          }
        }
      }
    }
    .cont_page {
      width: 50%;
      background: #333;
      position: relative;
      float: left;
      @include heightMatch;
      &_box {
        @include heightMatchCont;
        color: #fff;
        .tit {
          font-family: $font-family-lato;
          font-weight: 100;
          font-size: 50px;
          line-height: 1em;
          padding: 12% 10% 15px;
          position: relative;
          z-index: 3;
        }
        .tit_sub {
          font-family: $font-family-yugo;
          font-weight: 300;
          font-size: $fs-15;
          padding: 0 10%;
          position: relative;
          z-index: 3;
        }
        img {
          width: 100%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
        }
      }
    }
    .cont_page_02 {
      width: 50%;
      position: relative;
      float: left;
      background: $cl-lightGrayLight;
      border: 1px #fff solid;
      @include heightMatch;
      &_box {
        @include heightMatchCont;
        color: #333;
        .tit {
          color: #ccc;
          font-family: $font-family-lato;
          font-weight: 300;
          font-size: 42px;
          line-height: 1em;
          padding: 5% 8% 5%;
          position: relative;
          z-index: 3;
        }
        .tit_sub {
          font-family: $font-family-yugo;
          font-weight: 500;
          font-size: $font-size;
          padding: 0 8%;
          position: relative;
          z-index: 3;
        }
        .img {
          width: 100%;
          height: 60%;
          overflow: hidden;
          text-align: center;
          margin: 0 auto;
          position: relative;
          img {
            width: 100%;
            height: auto;
            text-align: center;
            margin: 0 auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
    .area_right {
      width: 50%;
      float: right;
      .cont_blog {
        width: 50%;
        background: url(../img/bg-wh.png);
        position: relative;
        float: left;
        @include heightMatch;
        &_box {
          @include heightMatchCont;
          padding: 7px;
          color: $cl-lightBlue;
          .blog_main {
            position: relative;
            z-index: 2;
            .blog_tit {
              height: 3em;
              box-sizing: content-box;
              overflow: hidden;
              font-size: $fs-18;
              line-height: 1.6;
              padding: 6% 4% 4%;
            }
            .blog_date {
              font-family: $font-family-lato;
              font-size: $fs-13;
              font-weight: 300;
              padding: 0 4%;
            }
          }
          .blog_img {
            width: 100%;
            height: 60%;
            overflow: hidden;
            text-align: center;
            margin: 0 auto;
            img {
              width: 100%;
              height: auto;
              overflow: hidden;
              text-align: center;
              margin: 0 auto;
            }
          }
          .blog_nav {
            position: absolute;
            bottom: 10%;
            right: 10%;
            a {
              color: $cl-lightBlue;
            }
          }
        }
      }
      a:nth-child(1) .cont_blog,
      a:nth-child(4) .cont_blog,
      a:nth-child(5) .cont_blog {
        background: $cl-lightGrayLight;
        &:after {
          content: '';
          display: block;
          width: 70px;
          height: 70px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(../img/arw-wh.png) no-repeat;
          background-size: contain;
          z-index: 99;
        }
      }
      a:nth-child(2) .cont_blog,
      a:nth-child(3) .cont_blog {
        background: #fff;
        &:after {
          content: '';
          display: block;
          width: 70px;
          height: 70px;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(../img/arw-gr.png) no-repeat;
          background-size: contain;
          z-index: 99;
        }
      }
      .cont_min-img_02 {
        width: 100%;
        img {
          float: left;
          width: 25%;
          height: auto;
        }
      }
    }
    .link_bnr {
      position: relative;
      overflow: hidden;
      &:after {
        content: '';
        display: block;
        width: 70px;
        height: 70px;
        position: absolute;
        bottom: 0;
        right: 0;
        background: url(../img/arw-wh.png) no-repeat;
        background-size: contain;
        z-index: 99;
      }
      &:hover {
        .hv-am {
          top: -25%;
          left: -25%;
        }
      }
      .hv-am {
        position: absolute;
        top: 100%;
        left: 100%;
        z-index: 2;
        width: 150%;
        height: 150%;
        transform: rotate(-45deg);
        background: rgba(28, 108, 192, 0.5);
        -webkit-transition: .6s;
        transition: .6s;
        opacity: 1;
      }
    }
  }
}
